import React from 'react';
import { planStatusEnum } from 'utils/enums';
import StatusLabelView from 'components/shared/status-label';

const StatusLabel = ({ status = 'In Review', size = 'base', addedText }) => {
  // DATA INITIALIZATION
  let containerSizeStyle = '';
  let containerTextBgStyles = '';
  let circleStyles = '';

  switch (size) {
    case 'lg':
      containerSizeStyle = 'text-lg px-4';
      break;
    case 'base':
      containerSizeStyle = 'text-base px-3';
      break;
    case 'sm':
      containerSizeStyle = 'text-sm px-2';
      break;
    case 'xs':
      containerSizeStyle = 'text-xs px-2';
      break;
    default:
      containerSizeStyle = 'text-base px-3';
  }

  // COLOR CODES:
  // GREEN:
  // containerTextBgStyles = 'text-[#34D399] bg-[#34D39926]';
  // circleStyles = 'bg-[#34D399]';
  // 
  // YELLOW:
  // containerTextBgStyles = 'text-[#9D5425] bg-[#FBBF241A]';
  // circleStyles = 'bg-[#FBBF24]';
  // 
  // RED:
  // containerTextBgStyles = 'text-[#BC1C21] bg-[#FEEAEA]';
  // circleStyles = 'bg-[#F87171]';
  // 
  // GREY (DEFAULT):
  // containerTextBgStyles = 'text-gray-600 bg-gray-200';
  // circleStyles = 'bg-gray-400';

  switch (status) {
    case planStatusEnum.pending:
      containerTextBgStyles = 'text-[#9D5425] bg-[#FBBF241A]';
      circleStyles = 'bg-[#FBBF24]';
      break;
    case planStatusEnum.active:
      containerTextBgStyles = 'text-[#34D399] bg-[#34D39926]';
      circleStyles = 'bg-[#34D399]';
      break;
    case planStatusEnum.complete:
      containerTextBgStyles = 'text-gray-600 bg-gray-200';
      circleStyles = 'bg-gray-400';
      break;
    case planStatusEnum.rejected:
      containerTextBgStyles = 'text-[#BC1C21] bg-[#FEEAEA]';
      circleStyles = 'bg-[#F87171]';
      break;
    case planStatusEnum.cancelled:
      containerTextBgStyles = 'text-[#BC1C21] bg-[#FEEAEA]';
      circleStyles = 'bg-[#F87171]';
      break;
    case planStatusEnum.created:
      containerTextBgStyles = 'text-gray-600 bg-gray-200';
      circleStyles = 'bg-gray-400';
      break;
    case planStatusEnum.review:
      containerTextBgStyles = 'text-[#9D5425] bg-[#FBBF241A]';
      circleStyles = 'bg-[#FBBF24]';
      break;
    default:
      containerTextBgStyles = 'text-gray-600 bg-gray-200';
      circleStyles = 'bg-gray-400';
  }

  const statusText = (addedText ? `${status} (${addedText})` : status)

  return (
    <StatusLabelView
      containerTextBgStyles={containerTextBgStyles}
      circleStyles={circleStyles}
      status={statusText}
      addedText={addedText}
      containerSizeStyle={containerSizeStyle}
    />
  );
};

export default StatusLabel;
