import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { IoMdSave } from 'react-icons/io';
import { InfoCard, InfoGrid, InfoField } from 'common/components/info-card';
import { toast } from 'react-toastify';
import ErrorData from 'components/shared/error-data';
import LoadingSpinner from 'components/shared/loading-spinner';
import * as Yup from 'yup';
import Input from 'components/shared/input';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import {
  useGetOwnerQuery,
  useUploadRequestUrlMutation,
  useUpdateOwnerMutation,
  useCreateOwnerMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import { Form, Formik } from 'formik';
import SelectInput from 'common/components/select-input';
import {
  ownerStatusEnum,
  ownerKindEnum,
  documentStatusEnum,
} from 'utils/enums';
import { useNavigate } from 'react-router-dom';
import FormError from 'components/shared/form-error';
import { ownerStatusOptions, ownerKindOptions } from 'utils/selectOptions';
import Button from 'common/components/button';
import Toggle from 'components/shared/toggle';
import { PhoneNumberInput } from 'common/components/phone-input';
import TextAreaInput from 'components/shared/text-area-input';
import { Icon } from '@chakra-ui/react';
import { FaChevronRight } from 'react-icons/fa6';

function View() {
  const { ownerId } = useParams();
  const navigate = useNavigate();
  const isNew = !ownerId;

  const [
    createOwner,
    { isLoading: isCreateLoading, isFetching: isCreateFetching },
  ] = useCreateOwnerMutation();
  const [
    updateOwner,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
  ] = useUpdateOwnerMutation();
  const {
    isError: isGetError,
    error: getError,
    data: ownerData,
  } = useGetOwnerQuery(ownerId, { skip: !ownerId });

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }
  if (!isNew && !ownerData) return <LoadingSpinner />;

  return (
    <Formik
      onSubmit={(value) => {
        
        if (isNew) {
          createOwner(value)
            .unwrap()
            .then((data) => {
              console.log(data);
              navigate(`/dashboard/owners/${data?.id}`);
            })
            .catch((e) => {
              toast.error(e?.data?.msg || 'Create Failed');
            });
        } else {
          updateOwner({ ...value, id: ownerData.id })
            .unwrap()
            .then(() => {
              navigate(-1);
            })
            .catch((e) => {
              toast.error(e?.data?.msg || 'Update Failed');
            });
        }
      }}
      initialValues={{
        name: ownerData?.name || '',
        email: ownerData?.email || '',
        phone: ownerData?.phone || '',
        website: ownerData?.website || '',
        notes: ownerData?.notes || '',
        flatCommission: ownerData?.flatCommission || 0,
        revShareRate: ownerData?.revShareRate || 0,
        status: ownerData?.status || ownerStatusEnum.NEW,
        kind: ownerData?.kind || ownerKindEnum.OWNER,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string(),
        phone: Yup.string(),
        website: Yup.string(),
        notes: Yup.string(),
        flatCommission: Yup.number(),
        revShareRate: Yup.number(),
      })}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        isValid,
        values,
        dirty: isDirty,
        errors,
        setFieldValue,
      }) => {
        return (
          <Form>
            <DashboardWrapperLayout title={ownerData?.name || 'Company Details'}>

              {/* Loading Animation */}
              {(isUpdateLoading || isUpdateFetching || isCreateFetching) && (
                <LoadingSpinner center={true} />
              )}
              
              <div className='flex flex-col w-full gap-6'>

                {/* Combined breadcrumb + edit toggle header */}
                <div className='w-full flex gap-3 justify-between'>
                  {/* breadcrumb */}
                  <div className='flex gap-3 items-center'>
                    <Link
                      to='/dashboard/home'
                      className='font-normal text-base text-[#CED0CE]'
                    >
                      Dashboard
                    </Link>
                    <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
                    <Link
                      to='/dashboard/owners'
                      className='text-[#CED0CE] text-base font-normal'
                    >
                      Companies{' '}
                    </Link>
                    <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
                    {ownerData?.id ? (<>
                      <Link
                        to={`/dashboard/owners/${ownerData?.id}`}
                        className='text-[#CED0CE] text-base font-normal'
                      >
                        {ownerData?.id.toUpperCase()}
                      </Link>
                      <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
                      <Link
                        to={`/dashboard/properties/${ownerData?.id}/edit`}
                        className='text-[#191923] text-base font-normal'
                      >
                        Edit{' '}
                      </Link>
                    </>) : (<>
                      <Link
                        to=''
                        className='text-[#191923] text-base font-normal'
                      >
                        New{' '}
                      </Link>
                    </>)}
                  </div>

                  <Toggle
                    selected={'edit'}
                    editPath={`/dashboard/owners/${ownerData?.id}/edit`}
                    viewPath={`/dashboard/owners/${ownerData?.id}`}
                  />
                </div>

                {/* Action buttons */}
                <div className='w-full flex gap-2 justify-end items-center'>
                  <Button
                    disabled={!isValid || !isDirty}
                    Icon={IoMdSave}
                    onClick={handleSubmit}
                    title='Save Changes'
                    wFull={false}
                    className='px-6'
                  />
                </div>

                {/* Owner Edit Table */}
                <InfoCard title='Company Information'>
                  <InfoGrid>
                    <InfoField title='Name'>
                      <Input
                        placeholderText='Name'
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='name'
                      />
                      <FormError name='name' />
                    </InfoField>
                    <InfoField title='Type'>
                      <SelectInput
                        options={ownerKindOptions}
                        placeholder='Select Type'
                        name='values.kind'
                        value={ownerKindOptions.find(
                          (kind) => kind.value === values.kind
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue('kind', selectedOption?.value || '');
                        }}
                        onBlur={handleBlur}
                      />
                    </InfoField>
                    <InfoField title='Status'>
                      <SelectInput
                        options={ownerStatusOptions}
                        placeholder='Select Status'
                        name='values.status'
                        value={ownerStatusOptions.find(
                          (status) => status.value === values.status
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            'status',
                            selectedOption?.value || ''
                          );
                        }}
                        onBlur={handleBlur}
                      />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Email'>
                      <Input
                        placeholderText='Email'
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='email'
                      />
                      <FormError name='email' />
                    </InfoField>
                    <InfoField title='Phone'>
                      <PhoneNumberInput
                        name='phone'
                        value={values.phone}
                        onChange={(e) => {
                          setFieldValue('phone', e);
                        }}
                        onBlur={handleBlur}
                      />
                      <FormError name='phone' />
                    </InfoField>
                    <InfoField title='Website'>
                      <Input
                        placeholderText='Website'
                        value={values.website}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='website'
                      />
                      <FormError name='website' />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Rev Share Rate'>
                      <Input
                        type='number'
                        placeholderText='Rev Share Rate'
                        value={values.revShareRate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='revShareRate'
                      />
                      <FormError name='revShareRate' />
                    </InfoField>
                    <InfoField title='Flat Commission'>
                      <Input
                        type='number'
                        placeholderText='Flat Comission Amount'
                        value={values.flatCommission}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='flatCommission'
                        prefix='$'
                        suffix='Per Plan'
                      />
                      <FormError name='flatCommission' />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Notes' className='col-span-4'>
                      <TextAreaInput
                        placeholderText='Notes'
                        value={values.notes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='notes'
                      />
                      <FormError name='notes' />
                    </InfoField>
                  </InfoGrid>
                </InfoCard>
              </div>
            </DashboardWrapperLayout>
          </Form>
        );
      }}
    </Formik>
  );
}

export default View;
