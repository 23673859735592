import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import PageNotFound from 'pages/PageNotFound';
import ClaimEdit from 'pages/dashboard/claims/Edit';
import ClaimView from 'pages/dashboard/claims/View';
import Claims from 'pages/dashboard/claims/Claims';
import Home from 'pages/dashboard/home/Home';
// import ManagerView from 'pages/dashboard/managers/View';
// import ManagerNew from 'pages/dashboard/managers/New';
// import ManagerEdit from 'pages/dashboard/managers/Edit';
// import Managers from 'pages/dashboard/managers/Managers';
import MemberView from 'pages/dashboard/members/View';
import MemberEdit from 'pages/dashboard/members/Edit';
import Members from 'pages/dashboard/members/Members';
import PlanEdit from 'pages/dashboard/plans/Edit';
import PlanView from 'pages/dashboard/plans/View';
import Plans from 'pages/dashboard/plans/Plans';
import RouteGuard from 'utils/routeGuard';
import Login from 'pages/auth/Login';
import PropertyFind from 'pages/dashboard/properties/Find';
import PropertyView from 'pages/dashboard/properties/View';
import PropertyEdit from 'pages/dashboard/properties/Edit';
import OwnerFind from 'pages/dashboard/owners/find';
import OwnerView from 'pages/dashboard/owners/view';
import OwnerEdit from 'pages/dashboard/owners/edit';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        {/* Not Found/ Invalid Route */}
        <Route path='*' element={<PageNotFound />} />

        <Route element={<RouteGuard />}>
          <Route path='/login' element={<Login />} />
          <Route path='/' element={<Home />} />
          <Route path='/dashboard/home' element={<Home />} />

          {/* Claims */}
          <Route path='/dashboard/claims' element={<Claims />} />
          <Route path='/dashboard/claims/:claimId' element={<ClaimView />} />
          <Route path='/dashboard/claims/:claimId/edit' element={<ClaimEdit />} />

          {/* Plans */}
          <Route path='/dashboard/plans' element={<Plans />} />
          <Route path='/dashboard/plans/new' element={<PlanEdit />} />
          <Route path='/dashboard/plans/:planId' element={<PlanView />} />
          <Route
            path='/dashboard/plans/:planId/edit'
            element={<PlanEdit />}
          />

          {/* Owners  */}
          <Route path='/dashboard/owners' element={<OwnerFind />} />
          <Route path='/dashboard/owners/new' element={<OwnerEdit />} />
          <Route path='/dashboard/owners/:ownerId' element={<OwnerView />} />
          <Route
            path='/dashboard/owners/:ownerId/edit'
            element={<OwnerEdit />}
          />

          {/* Members */}
          <Route path='/dashboard/members/new' element={<MemberEdit />} />
          <Route
            path='/dashboard/members/:memberId/edit'
            element={<MemberEdit />}
          />
          <Route path='/dashboard/members/:memberId' element={<MemberView />} />
          <Route path='/dashboard/members' element={<Members />} />

          {/* Properties */}
          <Route
            path='/dashboard/properties/:propertyId/edit'
            element={<PropertyEdit />}
          />
          <Route path='/dashboard/properties/new' element={<PropertyEdit />} />
          <Route
            path='/dashboard/properties/:propertyId'
            element={<PropertyView />}
          />
          <Route path='/dashboard/properties' element={<PropertyFind />} />

          {/* Will deprecate Managers and Manager Properties soon */}

          {/* Managers */}
          {/* <Route
            path='/dashboard/managers/:managerId/edit'
            element={<ManagerEdit />}
          />
          <Route path='/dashboard/managers/new' element={<ManagerNew />} />
          <Route path='/dashboard/managers' element={<Managers />} />
          <Route
            path='/dashboard/managers/:managerId'
            element={<ManagerView />}
          /> */}

          {/* Manager's Properties */}
          {/* <Route
            path='/dashboard/managers/:managerId/properties/:propertyId/edit'
            element={<PropertyEdit />}
          />
          <Route
            path='/dashboard/managers/:managerId/properties/:propertyId'
            element={<PropertyView />}
          /> */}
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
