import React from 'react';
import TextArea from 'components/shared/text-area-input';
import { toast } from 'react-toastify';
import ErrorData from 'components/shared/error-data';
import LoadingSpinner from 'components/shared/loading-spinner';
import { Formik } from 'formik';
import { toDatePickerFormat, toDDMMYYYY } from 'utils/date';
import { IoMdSave } from 'react-icons/io';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Button from 'common/components/button';
import DocumentPreview from 'common/components/document-preview';
import SelectInput from 'common/components/select-input';
import StatusLabel from 'components/claims/status-label';
import ClaimType from 'components/claims/claim-type';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import {
  useGetClaimQuery,
  useUpdateClaimMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import { claimStatusEnum, claimTypeEnum } from 'utils/enums';
import Toggle from 'components/shared/toggle';
import { InfoCard, InfoField, InfoGrid } from 'common/components/info-card';
import Input from 'components/shared/input';
import TextAreaInput from 'components/shared/text-area-input';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { Icon } from '@chakra-ui/react';
import { FaChevronRight } from 'react-icons/fa6';

const claimStatusOptions = [
  { label: 'Draft', value: claimStatusEnum.Draft },
  { label: 'Submitted', value: claimStatusEnum.Submitted },
  { label: 'Completed', value: claimStatusEnum.Completed },
  { label: 'In Review', value: claimStatusEnum.Review },
  { label: 'Expired', value: claimStatusEnum.Expired },
  { label: 'Denied', value: claimStatusEnum.Denied },
  { label: 'Approved', value: claimStatusEnum.Approved },
  { label: 'Confirmed', value: claimStatusEnum.Confirmed },
];

const ClaimView = () => {
  const navigate = useNavigate();
  const { claimId } = useParams();

  const {
    data: claimData,
    isError: isGetError,
    error: getError,
  } = useGetClaimQuery(claimId);

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }
  // TODO Have loading screen, or something better if no data available
  if (!claimData) {
    return <LoadingSpinner />;
  }

  const { userId: userData, planId: planData } = claimData;

  // Get document url based on the claim type
  const documentUrl =
    claimData?.newJob?.offerPdfUrl ||
    claimData?.purchasedHome?.offerPdfUrl ||
    claimData?.otherClaim?.supportingDocUrl;

  // Get the document name from the url
  const regex = /https:\/\/cios-api-images\.s3\.amazonaws\.com\/listings\/(.+)/;
  const match = documentUrl?.match(regex);
  const documentName = match ? match[1] : '';

  const { status: claimStatus } = claimData;

  console.log('Claim Data: ', claimData);

  return (
    <DashboardWrapperLayout title={`Claim ${claimId.toUpperCase()}`}>
      {/* Main Content */}
      <div className='flex flex-col w-full gap-6'>
        {/* Combined breadcrumb + edit toggle header */}
        <div className='w-full flex gap-3 justify-between'>
          {/* breadcrumb */}
          <div className='flex gap-3 items-center'>
            <Link
              to='/dashboard/home'
              className='font-normal text-base text-[#CED0CE]'
            >
              Dashboard
            </Link>
            <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
            <Link
              to='/dashboard/claims'
              className='text-[#CED0CE] text-base font-normal'
            >
              Claims
            </Link>
            <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
            <Link
              to={`/dashboard/claims/${claimId}`}
              className='text-[#191923] text-base font-normal'
            >
              {claimId.toUpperCase()}
            </Link>
          </div>

          <Toggle
            selected={'view'}
            editPath={`/dashboard/claims/${claimId}/edit`}
            viewPath={`/dashboard/claims/${claimId}`}
          />
        </div>

        {/* Action Buttons */}
        <div className='w-full flex gap-2 justify-end items-center'>
          <Button
            onClick={() => {
              navigate(`/dashboard/members/${userData.id}`);
            }}
            title='View Member Details'
            theme='secondary'
            wFull={false}
            className='px-6'
          />
          <Button
            onClick={() => {
              navigate(`/dashboard/plans/${planData.id}`);
            }}
            title='View Plan Details'
            theme='secondary'
            wFull={false}
            className='px-6'
          />
        </div>

        {/* Viewable Fields for Reference */}
        <InfoCard title='Submitted Information'>
          {/* shared information for all claim types */}
          <InfoGrid>
            <InfoField title='Claim Number'>{claimData?.id}</InfoField>
            <InfoField title='Submitted On'>
              {formatDateToHumanDate(claimData?.submissionAt)}
            </InfoField>
            <InfoField title='Cancellation Date'>
              {formatDateToHumanDate(claimData?.moveOutDate)}
            </InfoField>
            <InfoField title='Claim Type'>
              {claimData?.claimType === claimTypeEnum.NEW_JOB
                ? 'New Job'
                : claimData?.claimType === claimTypeEnum.PURCHASED_HOME
                ? 'Purchased Home'
                : 'Other Claim'}
            </InfoField>
          </InfoGrid>

          {/* information for new jobs */}
          {claimData?.claimType === claimTypeEnum.NEW_JOB && (
            <InfoGrid>
              <InfoField title='Job Title'>
                {claimData?.newJob?.jobTitle}
              </InfoField>
              <InfoField title='Contact Name'>
                {claimData?.newJob?.contactName}
              </InfoField>
              <InfoField title='Contact Phone'>
                {claimData?.newJob?.contactPhone}
              </InfoField>
              <InfoField title='Contact Email'>
                {claimData?.newJob?.contactEmail}
              </InfoField>
              <InfoField title='Offer Date'>
                {formatDateToHumanDate(claimData?.newJob?.offerDate)}
              </InfoField>
              <InfoField title='Proposed Start Date'>
                {formatDateToHumanDate(claimData?.newJob?.proposedStart)}
              </InfoField>
            </InfoGrid>
          )}

          {/* information for purchased homes */}
          {claimData?.claimType === claimTypeEnum.PURCHASED_HOME && (
            <InfoGrid>
              <InfoField title='Street Address 1' className='col-span-2'>
                {claimData?.purchasedHome?.homeAddress?.streetAddress1}
              </InfoField>
              <InfoField title='Street Address 2' className='col-span-2'>
                {claimData?.purchasedHome?.homeAddress?.streetAddress2}
              </InfoField>
              <InfoField title='City'>
                {claimData?.purchasedHome?.homeAddress?.city}
              </InfoField>
              <InfoField title='State'>
                {claimData?.purchasedHome?.homeAddress?.state}
              </InfoField>
              <InfoField title='Zip Code'>
                {claimData?.purchasedHome?.homeAddress?.postalCode}
              </InfoField>
              <InfoField title='Country'>
                {claimData?.purchasedHome?.homeAddress?.country}
              </InfoField>
              <InfoField title='Offer Date'>
                {formatDateToHumanDate(claimData?.purchasedHome?.offerDate)}
              </InfoField>
              <InfoField title='Expected Possession Date'>
                {formatDateToHumanDate(claimData?.purchasedHome?.proposedStart)}
              </InfoField>
            </InfoGrid>
          )}

          {/* information for other claims */}
          {claimData?.claimType === claimTypeEnum.OTHER_CLAIM && (
            <InfoGrid>
              <InfoField title='Claim Description' className='col-span-4'>
                {claimData?.otherClaim?.description}
              </InfoField>
            </InfoGrid>
          )}

          {/* Submitted Documents */}
          <InfoGrid>
            <InfoField title='Submitted Documents' className='col-span-4'>
              <DocumentPreview url={documentUrl} label={documentName} />
            </InfoField>
          </InfoGrid>
        </InfoCard>

        {/* Status and Results */}
        <InfoCard title='Claim Status'>
          <InfoGrid>
            <InfoField title='Status'>
              <StatusLabel status={claimStatus} />
            </InfoField>
          </InfoGrid>

          {/* Show Approval if status=approved */}
          {claimStatus === claimStatusEnum.Approved && (
            <InfoGrid>
              <InfoField title='Approved Cancellation Date'>
                {formatDateToHumanDate(claimData?.approvedFeedback?.approvedDate)}
              </InfoField>
              <InfoField title='Deductible'>
                ${claimData?.approvedFeedback?.deductible} {planData?.currency}
              </InfoField>
              <InfoField title='Approved At'>
                {formatDateToHumanDate(claimData?.approvedFeedback?.approvedAt)}
              </InfoField>
              <InfoField title='Approval Feedback' className='col-span-4'>
                {claimData?.approvedFeedback?.feedback}
              </InfoField>
            </InfoGrid>
          )}

          {/* Show Denial if status=denied */}
          {claimStatus === claimStatusEnum.Denied && (
            <InfoGrid>
              <InfoField title='Denied On'>
                {formatDateToHumanDate(claimData?.deniedFeedback?.deniedAt)}
              </InfoField>
              <InfoField title='Denial Feedback' className='col-span-4'>
                {claimData?.deniedFeedback?.feedback}
              </InfoField>
            </InfoGrid>
          )}
        </InfoCard>
      </div>
    </DashboardWrapperLayout>
  );
};

export default ClaimView;
