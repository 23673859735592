import React from 'react';
import { BiEdit } from 'react-icons/bi';
import { useNavigateSearch } from 'hooks/useNavigateSearch';
import StatusLabel from 'components/plans/status-label';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import SortButton from 'common/components/sort-button';
import { CopyIcon, EmailIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Link,
} from '@chakra-ui/react';
import {
  ViewIcon,
  AddIcon,
  RepeatIcon,
  LockIcon,
  CloseIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from '@chakra-ui/icons';
import {
  Table,
  HeaderRow,
  HeaderCell,
  TableBody,
  BodyRow,
  TableCell,
} from 'common/components/data-table';
import { FaPeopleLine, FaStripe, FaStripeS } from 'react-icons/fa6';

// isRounded controls the top border radius rounding. Use when there is a header above the normal header row
// showMember controls whether the member name+email is shown. Use when there is no reason to show this info, like on the member plans page.

const PlansTable = ({
  plans,
  isRounded = true,
  tenantName,
  tenantEmail,
  sortVal,
  setSort,
  showMember = true,
}) => {
  // DATA INITIALIZATION
  const navigate = useNavigateSearch();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {},
      (err) => {
        console.error('Failed to copy text: ', err);
      }
    );
  };

  // console.log(plans)

  return (
    <Table>
      {/* head */}
      <HeaderRow
        isRounded={isRounded}
        gridLayout={
          showMember
            ? 'grid-cols-[220px_0.75fr_1fr_120px_180px]'
            : 'grid-cols-[220px_1fr_120px_180px]'
        }
      >
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='createdAt'
            label='Plan ID'
          />
        </HeaderCell>
        {showMember && <HeaderCell>Member</HeaderCell>}
        <HeaderCell>Address</HeaderCell>
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='lease.startDate'
            label='Term'
          />
        </HeaderCell>
        <HeaderCell>Status</HeaderCell>
      </HeaderRow>

      {/* body */}
      <TableBody>
        {plans.map((plan, index) => (
          <BodyRow
            gridLayout={
              showMember
                ? 'grid-cols-[220px_0.75fr_1fr_120px_180px]'
                : 'grid-cols-[220px_1fr_120px_180px]'
            }
            key={index}
            onClick={() => {
              navigate(`/dashboard/plans/${plan.id}`);
            }}
          >
            {/* Plan ID and Create Date */}
            <TableCell
              top={plan?.id}
              bottom={`Created ${formatDateToHumanDate(plan?.createdAt)}`}
            />

            {/* profile */}
            {showMember && (
              <TableCell
                top={
                  tenantName || plan?.user?.name
                    ? plan?.user?.name
                    : `${plan?.user?.firstName} ${plan?.user?.lastName}`
                }
                bottom={tenantEmail || plan?.user?.email}
                bottomLink={`mailto:${tenantEmail || plan?.user?.email}`}
                onClick={(e) => {
                  e.stopPropagation();
                  return false;
                }}
              />
            )}

            {/* Address */}
            {/* Placeholder when address is undefined (shouldn't happen) */}
            {plan?.lease?.address?.streetAddress1 ? (
              <TableCell
                top={`${plan?.lease?.address.streetAddress1}${
                  plan?.lease?.address.streetAddress2 ? `, ${plan?.lease?.address.streetAddress2}` : ''}`}
                bottom={`${plan?.lease?.address.city}, ${plan?.lease?.address.state} ${plan?.lease?.address.postalCode} (${plan?.lease?.landlordName})`}
              />
            ) : (
              <TableCell top='-' bottom='-' />
            )}

            {/* Start and End Date */}
            <TableCell>
              <div className='flex flex-col'>
                <span>{formatDateToHumanDate(plan?.lease?.startDate)}</span>
                <span>{formatDateToHumanDate(plan?.lease?.endDate)}</span>
              </div>
            </TableCell>

            {/* Status and Edit button */}
            <TableCell
              className='justify-between'
              onClick={(e) => {
                e.stopPropagation();
                return false;
              }}
            >
              {plan?.status == 'pending' ? (
                <StatusLabel
                  size='sm'
                  status={plan?.status}
                />
              ) : (
                <StatusLabel size='sm' status={plan?.status} />
              )}
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label='Options'
                  icon={<HamburgerIcon />}
                  variant='outline'
                />
                <MenuList>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/plans/${plan?.id}`);
                    }}
                    icon={<ViewIcon />}
                  >
                    View Plan Details
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/plans/${plan?.id}/edit`);
                    }}
                    icon={<AddIcon />}
                  >
                    Edit Plan Details
                  </MenuItem>
                  {plan?.stripePaymentID || plan?.stripeSubscriptionID ? (
                    <MenuItem
                      onClick={(e) => {
                        if (plan?.stripePaymentID) {
                          let url = `${process.env.REACT_APP_STRIPE_DASHBOARD_URL}payments/${plan?.stripePaymentID}`;
                          window.open(url, '_blank');
                        } else if (plan?.stripeSubscriptionID) {
                          let url = `${process.env.REACT_APP_STRIPE_DASHBOARD_URL}subscriptions/${plan?.stripeSubscriptionID}`;
                          window.open(url, '_blank');
                        } else {
                          let url = `${process.env.REACT_APP_STRIPE_DASHBOARD_URL}`;
                          window.open(url, '_blank');
                        }
                      }}
                      icon={<FaStripeS />}
                    >
                      Open in Stripe
                    </MenuItem>
                  ): null}
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/members/${plan?.user?.id}`);
                    }}
                    icon={<FaPeopleLine />}
                  >
                    View Member Details
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipboard(tenantEmail || plan?.user?.email);
                    }}
                    icon={<CopyIcon />}
                  >
                    Copy Member Email
                  </MenuItem>
                </MenuList>
              </Menu>
            </TableCell>
          </BodyRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default PlansTable;
