import { useEffect, useState } from 'react';
import ErrorData from 'components/shared/error-data';
import { Link, useSearchParams } from 'react-router-dom';
import PlansTable from 'components/plans/plans-table';
import EmptyData from 'components/shared/empty-data';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import TablePaginateWrapper from 'layouts/table-paginate-wrapper';
import LoadingSpinner from 'components/shared/loading-spinner';
import { ACTIVE_PLAN } from 'utils/enums';
import { isEmpty } from 'common/utils/isEmpty';
import { useFindPlansQuery } from 'redux/api/ciosAdminApi/ciosAdminApi';
import { planStatusEnum } from 'utils/enums';
import CheckBox from 'components/shared/checkbox';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react';
import Input from 'components/shared/input';
import Button from 'common/components/button';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@chakra-ui/react';
import { FaChevronRight } from 'react-icons/fa6';

function ToggleArea({ statuses, setCurrentStatuses }) {
  console.log(statuses);
  const handleToggle = (status) => {
    if (statuses.includes(status)) {
      setCurrentStatuses(statuses.filter((s) => s !== status));
    } else {
      setCurrentStatuses([...statuses, status]);
    }
  };

  return (
    <Accordion
      allowMultiple
      className='flex w-full flex-col border border-slate-200 rounded-md overflow-hidden'
    >
      <AccordionItem className='border-none'>
        <h2>
          <AccordionButton className='bg-[#E1E6EF] hover:bg-slate-100 px-3'>
            <div className='w-full text-left text-sm font-semibold py-1 uppercase'>
              Filter by Status
            </div>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel className='border-t py-4'>
          {/* Options */}
          <div className='grid grid-cols-4 gap-4'>
            {Object.keys(planStatusEnum).map((key) => (
              <label key={key} className='flex items-center gap-2 text-sm'>
                <CheckBox
                  name={planStatusEnum[key]}
                  onChange={() => handleToggle(planStatusEnum[key])}
                  checked={statuses.includes(planStatusEnum[key])}
                />
                {planStatusEnum[key]}
              </label>
            ))}
          </div>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

const FindPlans = () => {
  const navigate = useNavigate();
  const pageSize = 25;

  const [currentPage, setCurrentPage] = useState(1);
  const [sortVal, setSort] = useState('-createdAt');
  const [textFilter, setTextFilter] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [statuses, setCurrentStatuses] = useState([]);

  const {
    data: plansQuery,
    isError: isGetError,
    error: getError,
    isLoading: findPlansLoading,
  } = useFindPlansQuery({
    limit: pageSize,
    page: currentPage,
    sort: sortVal,
    textFilter,
    status: statuses,
  });

  const { data: plansData, meta: plansMeta } = plansQuery || {
    data: [],
    meta: { count: 0 },
  };
  console.log('All plans?', plansData);

  // HOOKS

  // Update URL when filters change
  useEffect(() => {
    const params = {};
    if (statuses.length > 0) {
      params.statuses = statuses.join(',');
    }

    if (textFilter) {
      params.textFilter = textFilter;
    }
    setSearchParams(params);
  }, [statuses, textFilter, setSearchParams]);

  // Pull query variables from the URL to set the initial state
  useEffect(() => {
    const statusQuery = searchParams.get('statuses');
    if (statusQuery) {
      setCurrentStatuses(statusQuery.split(','));
    } else {
      setCurrentStatuses([
        planStatusEnum.created,
        planStatusEnum.pending,
        planStatusEnum.review,
        planStatusEnum.active,
      ]);
    }
  }, [searchParams]);

  // Error handling
  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }

  return (
    <DashboardWrapperLayout title='Plans'>
      <div className='flex flex-col w-full gap-6'>
        {/* breadcrumb */}
        <div className='flex gap-3 items-center'>
          <Link
            to='/dashboard/home'
            className='font-normal text-base text-[#CED0CE]'
          >
            Dashboard
          </Link>
          <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
          <Link
            to='/dashboard/plans'
            className='font-normal text-base text-[#191923]'
          >
            Plans
          </Link>
        </div>

        {/* Search + Create Button */}
        {/* Search won't work until we add textFilter to the get plans api */}
        <div className='w-full flex gap-3 justify-between items-center'>
          {
            <Input
              placeholderText='Search'
              value={textFilter}
              onChange={(e) => {
                setTextFilter(e.target.value);
              }}
              name='textFilter'
              className='max-w-lg'
            />
          }
          <Button
            title='Add Plan'
            onClick={() => navigate(`/dashboard/plans/new`)}
            wFull={false}
            className='px-8'
          />
        </div>

        {/* Filter Area */}
        <ToggleArea
          statuses={statuses}
          setCurrentStatuses={setCurrentStatuses}
        />

        {/* table */}
        {findPlansLoading && <LoadingSpinner center={true} />}
        {isEmpty(plansData) ? (
          <EmptyData />
        ) : (
          <TablePaginateWrapper
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalCount={plansMeta.count}
          >
            <PlansTable
              setSort={setSort}
              sortVal={sortVal}
              planType={ACTIVE_PLAN}
              plans={plansData}
            />
          </TablePaginateWrapper>
        )}
      </div>
    </DashboardWrapperLayout>
  );
};

export default FindPlans;
