import React from 'react';

function capitalizeStatus(str) {
  return str
    .split(/[-_ ]/) // Split the string on hyphens and underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each part and make the rest lowercase
    .join(' '); // Join the parts back together with a hyphen
}

const StatusLabel = ({
  status = 'Unknown',
  containerSizeStyle,
  containerTextBgStyles,
  circleStyles,
}) => {
  return (
    <div
      className={`inline-flex w-fit py-[3px] ${containerSizeStyle} ${containerTextBgStyles} font-normal items-center gap-2 rounded-2xl`}
    >
      <span className={`h-[12px] w-[12px] ${circleStyles} rounded-[50%]`} />
      {capitalizeStatus(status)}
    </div>
  );
};

export default StatusLabel;
