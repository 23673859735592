import { HiBadgeCheck } from 'react-icons/hi';
import { Flex, Icon } from '@chakra-ui/react';

const ProgressBar = ({ children }) => {
  return <Flex className='w-full rounded-full bg-slate-100 overflow-hidden'>{children}</Flex>;
};

const ProgressBarStep = ({ title, complete = false, inProgress = false }) => {
  return (
    <Flex
      className={`w-full gap-2 py-2 px-3 justify-center items-center font-semibold text-center text-sm ${
        complete ? 'bg-black' : inProgress ? 'bg-slate-400' : 'bg-slate-100'
      } ${complete || inProgress ? 'text-white' : 'text-black'}`}
    >
      {title}
      {complete && <Icon as={HiBadgeCheck} className='text-white text-xl' />}
    </Flex>
  );
};

export { ProgressBar, ProgressBarStep };
