import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import endpoints from '../endpoints.json';
import qs from 'qs';
import defaultFetchFn from '../fetch';
import fetchFn from '../overrides/fetch';

if (process.env.environment === 'production' && fetchFn !== undefined) {
  console.error(
    'You are importing the dev fetch fn, going to override it, but need to fix this'
  );
  fetchFn = defaultFetchFn;
}

const prepareQuery = (slug, { limit, page, textFilter, ...args }) => {
  let query = { limit, ...args };
  if (page > 1) {
    query.offset = (page - 1) * limit;
  }

  if (textFilter) {
    query.textFilter = textFilter;
  }
  return `${slug}?${qs.stringify(query)}`;
};

// VARIABLES
const CIOSADMIN_API_REDUCER_KEY = 'ciosAdminApi';
const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;

// Base Query
const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  timeout: 1500 * 60,
  prepareHeaders: (headers, { getState, endpoint }) => {
    // getting access token from store
    const token = getState().auth.token;

    if (token && endpoint !== 'refresh') {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
  fetchFn: fetchFn ? fetchFn : defaultFetchFn,
});

// API Slice
export const ciosAdminApi = createApi({
  reducerPath: CIOSADMIN_API_REDUCER_KEY,
  baseQuery: baseQuery,
  refetchOnReconnect: true,
  tagTypes: [
    'User Details',
    'Claims',
    'Plans',
    'Stripe Payment',
    'Properties',
    'Managers',
    'Owners',
  ],
  endpoints: (builder) => ({
    // AUTHENTICATION
    login: builder.mutation({
      query: (body) => ({
        url: endpoints.authentication.login,
        method: 'POST',
        body,
      }),
    }),

    // Admin Summary
    getAdminSummary: builder.query({
      query: () => {
        return { url: `${endpoints.admins.summary}` };
      },
      transformResponse: (response) => {
        return response?.data;
      },
    }),
    // CLAIMS
    findClaims: builder.query({
      query: ({ limit, page, ...args }) => {
        return {
          url: prepareQuery(endpoints.claims.find, { limit, page, ...args }),
        };
      },
      providesTags: ['Claims'],
      transformResponse: (response) => {
        return response;
      },
    }),
    getClaim: builder.query({
      query: (id) => {
        return { url: `${endpoints.claims.get}${id}` };
      },
      providesTags: ['Claims'],
      transformResponse: (response) => {
        return response?.data;
      },
    }),
    updateClaim: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `${endpoints.claims.update}${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Claims'],
      transformResponse: (response) => response?.data,
    }),

    // PLANS
    findPlans: builder.query({
      query: ({ limit, page, ...args }) => {
        return {
          url: prepareQuery(endpoints.plans.find, { limit, page, ...args }),
        };
      },
      providesTags: ['Plans'],
      transformResponse: (response) => {
        return response;
      },
    }),
    getPlan: builder.query({
      query: (id) => {
        return { url: `${endpoints.plans.get}${id}` };
      },
      providesTags: ['Plans'],
      transformResponse: (response) => {
        return response;
      },
    }),
    createPlan: builder.mutation({
      query: ({ ...data }) => ({
        url: `${endpoints.plans.create}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Plans'],
      transformResponse: (response) => response?.data,
    }),
    updatePlan: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `${endpoints.plans.update}${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Plans'],
      transformResponse: (response) => response?.data,
    }),

    // OWNERS
    findOwners: builder.query({
      query: ({ limit, page, ...args }) => {
        return {
          url: prepareQuery(endpoints.owners.find, { limit, page, ...args }),
        };
      },
      providesTags: ['Owners'],
      transformResponse: (response) => {
        return response;
      },
    }),
    deleteOwner: builder.mutation({
      query: ({ id }) => ({
        url: `${endpoints.owners.delete}${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Owners'],
      transformResponse: (response) => response?.data,
    }),
    createOwner: builder.mutation({
      query: ({ ...data }) => ({
        url: `${endpoints.owners.create}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Owners'],
      transformResponse: (response) => response?.data,
    }),
    getOwner: builder.query({
      query: (id) => {
        return { url: `${endpoints.owners.get}${id}` };
      },
      providesTags: ['Owners'],
      transformResponse: (response) => {
        return response?.data;
      },
    }),
    updateOwner: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `${endpoints.owners.update}${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Owners'],
      transformResponse: (response) => response?.data,
    }),

    // TENANTS
    findTenants: builder.query({
      query: ({ limit, page, ...args }) => {
        return {
          url: prepareQuery(endpoints.tenants.find, { limit, page, ...args }),
        };
      },
      providesTags: ['Tenants'],
      transformResponse: (response) => {
        return response;
      },
    }),
    getTenant: builder.query({
      query: (id) => {
        return { url: `${endpoints.tenants.get}${id}` };
      },
      providesTags: ['Tenants'],
      transformResponse: (response) => {
        return response?.data;
      },
    }),
    impersonateTenant: builder.mutation({
      query: ({ id }) => ({
        url: `${endpoints.tenants.get}${id}/login-token`,
        method: 'POST',
      }),
      providesTags: ['Tenants'],
      transformResponse: (response) => {
        return response?.data;
      },
    }),
    claimAccountTenantReq: builder.mutation({
      query: ({ id, ...args }) => ({
        url: `${endpoints.tenants.get}${id}/claim-account-request`,
        method: 'POST',
        body: {
          ...args,
        },
      }),
      providesTags: ['Tenants'],
      transformResponse: (response) => {
        return response?.data;
      },
    }),
    createTenant: builder.mutation({
      query: ({ ...data }) => ({
        url: `${endpoints.tenants.create}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Tenants'],
      transformResponse: (response) => response?.data,
    }),
    updateTenant: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `${endpoints.tenants.update}${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Tenants'],
      transformResponse: (response) => response?.data,
    }),
    deleteTenant: builder.mutation({
      query: ({ id }) => ({
        url: `${endpoints.tenants.delete}${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tenants'],
      transformResponse: (response) => response?.data,
    }),

    // MANAGERS
    findManagers: builder.query({
      query: ({ limit, page, ...args }) => {
        return {
          url: prepareQuery(endpoints.managers.find, { limit, page, ...args }),
        };
      },
      providesTags: ['Managers'],
      transformResponse: (response) => {
        return response;
      },
    }),
    getManager: builder.query({
      query: (id) => {
        return { url: `${endpoints.managers.get}${id}` };
      },
      providesTags: ['Managers'],
      transformResponse: (response) => {
        return response?.data;
      },
    }),
    updateManager: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `${endpoints.managers.update}${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Managers'],
      transformResponse: (response) => response?.data,
    }),
    deleteManager: builder.mutation({
      query: ({ id }) => ({
        url: `${endpoints.managers.delete}${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Managers'],
      transformResponse: (response) => response?.data,
    }),
    newManagers: builder.mutation({
      query: ({ ...data }) => ({
        url: `${endpoints.managers.new}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Managers'],
      transformResponse: (response) => response?.data,
    }),

    // PROPERTIES
    getProperty: builder.query({
      query: (id) => {
        return { url: `${endpoints.properties.get}${id}` };
      },
      providesTags: ['Properties'],
      transformResponse: (response) => {
        return response?.data;
      },
    }),
    findProperty: builder.query({
      query: ({ limit, page, ...args }) => {
        return {
          url: prepareQuery(endpoints.properties.find, {
            limit,
            page,
            ...args,
          }),
        };
      },
      providesTags: ['Properties'],
      transformResponse: (response) => {
        return response;
      },
    }),
    newProperty: builder.mutation({
      query: ({ ...data }) => ({
        url: `${endpoints.properties.new}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Properties', 'Managers'],
      transformResponse: (response) => response?.data,
    }),
    updateProperty: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `${endpoints.properties.update}${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Properties', 'Managers'],
      transformResponse: (response) => response?.data,
    }),
    deleteProperty: builder.mutation({
      query: ({ id }) => ({
        url: `${endpoints.properties.delete}${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Properties', 'Managers'],
      transformResponse: (response) => response?.data,
    }),

    // Uploads
    uploadRequestUrl: builder.mutation({
      query: (body) => {
        return {
          url: endpoints.upload.uploadRequestUrl,
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

// exporting Query Hooks
export const {
  // AUTH
  useLoginMutation,

  // Admin Summary
  useGetAdminSummaryQuery,

  // CLAIMS
  useFindClaimsQuery,
  useGetClaimQuery,
  useUpdateClaimMutation,

  // PLANS
  useFindPlansQuery,
  useGetPlanQuery,
  useUpdatePlanMutation,
  useCreatePlanMutation,

  // Tenants
  useFindTenantsQuery,
  useGetTenantQuery,
  useImpersonateTenantMutation,
  useClaimAccountTenantReqMutation,
  useCreateTenantMutation,
  useUpdateTenantMutation,
  useDeleteTenantMutation,

  // Owners
  useFindOwnersQuery,
  useGetOwnerQuery,
  useUpdateOwnerMutation,
  useCreateOwnerMutation,
  useDeleteOwnerMutation,

  // Managers
  useFindManagersQuery,
  useGetManagerQuery,
  useDeleteManagerMutation,
  useUpdateManagerMutation,
  useNewManagersMutation,

  // Properties
  useGetPropertyQuery,
  useFindPropertyQuery,
  useNewPropertyMutation,
  useUpdatePropertyMutation,
  useDeletePropertyMutation,

  // Uploads
  useUploadRequestUrlMutation,
} = ciosAdminApi;
