import React, { useState } from 'react';
import {
  CANCEL_ANYTIME_RENT_PERCENTAGE,
  LEAVE_ANYTIME_RENT_PERCENTAGE,
  LEAVE_ANYTIME_ONETIME_DISCOUNT,
} from 'common/utils/consts';
import Toggle from 'components/shared/toggle';
import AutoCompleteAddress from 'components/shared/autoaddress';
import * as Yup from 'yup';
import moment from 'moment';
import { isEmpty } from 'utils/isEmpty';
// import FormError from "@cios/common/dist/components/shared/form-error";
// import { FormError } from "@cios/common";
import FormError from 'components/shared/form-error';
import DocumentPreview from 'common/components/document-preview';
import ErrorData from 'components/shared/error-data';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Input from 'components/shared/input';
import SelectInput from 'common/components/select-input';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { ACTIVE_PLAN, REQUESTED_PLAN } from 'utils/enums';
import { coverageTypes, planPaymentMode, statusEnum } from 'common/utils/enums';
import { toDDMMYYYY, toDatePickerFormat } from 'utils/date';
import { uploadFileToS3 } from 'utils/uploadS3';
import {
  useGetPlanQuery,
  useUpdatePlanMutation,
  useUploadRequestUrlMutation,
  useCreatePlanMutation,
  useImpersonateTenantMutation,
  useFindPropertyQuery,
  useFindTenantsQuery,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import { planStatusEnum } from 'utils/enums';
import LoadingSpinner from 'components/shared/loading-spinner';
import filterLabelValues from 'utils/filterLabelValues';
import FileUploadInput from 'common/components/file-upload-input';
import {
  documentStatusOptions,
  planCurrencyOptions,
  residencyStatusOptions,
} from 'utils/selectOptions';
import Button from 'common/components/button';
import { InfoCard, InfoGrid, InfoField } from 'common/components/info-card';
import { Icon } from '@chakra-ui/react';
import { FaChevronRight } from 'react-icons/fa6';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { ProgressBar, ProgressBarStep } from 'components/shared/progress-bar';
import { extractDocumentName } from 'common/utils/extractDocumentName';

// Can get the number of months here
function getTerm(startDate, endDate) {
  if (!startDate || !endDate) {
    return 12;
  }

  let start = moment(startDate);
  let end = moment(endDate);

  return end.diff(start, 'months');
}

// These are the default values if you want to use them
function getCancelAnytimeConfig({ plan }) {
  const lease = plan?.lease;
  const { startDate, endDate, monthlyRent } = lease;
  const term = getTerm(startDate, endDate);
  const total = monthlyRent * term;
  const oneTimeAmount = total * CANCEL_ANYTIME_RENT_PERCENTAGE;
  return {
    term,
    startDate,
    endDate,
    payment: {
      amount: oneTimeAmount,
      rentPercentage: CANCEL_ANYTIME_RENT_PERCENTAGE,
    },
  };
}

function getLeaveAnytimeConfig({ plan }) {
  const lease = plan?.lease;
  const { startDate, endDate, monthlyRent } = lease;
  const term = getTerm(startDate, endDate);
  const total = monthlyRent * term * LEAVE_ANYTIME_RENT_PERCENTAGE;
  const subscriptionAmount = total / term;
  const oneTimeAmount = total * (1 - LEAVE_ANYTIME_ONETIME_DISCOUNT);
  return {
    term,
    startDate,
    endDate,
    payment: {
      [planPaymentMode.ONE_TIME]: {
        amount: oneTimeAmount,
        discountPercentage: LEAVE_ANYTIME_ONETIME_DISCOUNT,
        rentPercentage: LEAVE_ANYTIME_RENT_PERCENTAGE,
        effectiveMonthlyRate: oneTimeAmount / term,
      },
      [planPaymentMode.SUBSCRIPTION]: {
        amount: subscriptionAmount,
        rentPercentage: LEAVE_ANYTIME_RENT_PERCENTAGE,
      },
    },
  };
}

function getLastStatusMessage(plan) {
  if (plan.status === planStatusEnum.rejected && plan.rejectedLeases) {
    let len = plan.rejectedLeases.length;
    return plan.rejectedLeases[len - 1].reason;
  } else {
    return '';
  }
}

const allPlanStatusOptions = [
  { label: 'Active', value: planStatusEnum.active },
  { label: 'Pending', value: planStatusEnum.pending },
  { label: 'Complete', value: planStatusEnum.complete },
  { label: 'Rejected', value: planStatusEnum.rejected },
  { label: 'Cancelled', value: planStatusEnum.cancelled },
  { label: 'Created', value: planStatusEnum.created },
  { label: 'Review', value: planStatusEnum.review },
];

const booleanOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

const PlanDetails = () => {
  const navigate = useNavigate();
  const { planId } = useParams();

  // Get list of tenants for dropdown

  const {
    data: tenantsQuery,
    isError: isFindTenantsError,
    error: getTenantsError,
    isLoading: findTenantsLoading,
  } = useFindTenantsQuery({
    limit: 200,
    page: 1,
    sort: '-createdAt',
    // textFilter,
    // status: 'Active',
  });

  const { data: tenantsData, meta: tenantsMeta } = tenantsQuery || {
    data: [],
    meta: { count: 0 },
  };

  const createTenantOptionMenu = (obj) => {
    if (isEmpty(obj)) return [];

    return obj?.map(
      // eslint-disable-next-line no-unused-vars
      ({ createdAt, id, name, firstName, lastName, email, ...res }) => ({
        label: (
          <div className='w-full flex gap-2 justify-between'>
            <div className='text-base font-semibold'>{name || `${firstName} ${lastName}` || 'Member'}</div>
            <div className='grid grid-cols-[1fr_100px] gap-2 text-base font-normal'>
              <span>{email}</span>
              <span className='text-right'>{formatDateToHumanDate(createdAt)}</span>
            </div>
          </div>
        ),
        value: id,
      })
    );
  };

  // Get list of properties for dropdown

  const {
    data: propertiesQuery,
    isError: isFindPropertiesError,
    error: getPropertyError,
    isLoading: findPropertiesLoading,
  } = useFindPropertyQuery({
    limit: 200,
    page: 1,
    sort: '-createdAt',
    // textFilter
  });

  const { data: propertiesData, meta: propertiesMeta } = propertiesQuery || {
    data: [],
    meta: { count: 0 },
  };

  const createPropertyOptionMenu = (obj) => {
    if (isEmpty(obj)) return [];

    return obj?.map(
      // eslint-disable-next-line no-unused-vars
      ({ createdAt, id, name, address, ...res }) => ({
        label: (
          <div className='w-full flex gap-2 justify-between'>
            <div className='text-base font-semibold'>{name || 'Building'}</div>
            <div className='text-base font-normal'>
              {address?.streetAddress1}, {address?.city}
            </div>
          </div>
        ),
        value: id,
      })
    );
  };

  const [
    updatePlan,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
  ] = useUpdatePlanMutation();

  const [impersonateTenant, { isLoading: isImpersonateLoading }] =
    useImpersonateTenantMutation();

  const [
    createPlan,
    { isLoading: isCreateLoading, isFetching: isCreateFetching },
  ] = useCreatePlanMutation();

  const queryParams = new window.URLSearchParams(window.location.search);
  const userId = queryParams.get('userId');
  const propertyId = queryParams.get('property');

  console.log('User id?', userId);
  console.log('Property id?', propertyId);

  const [uploadStatus, setUploadStatus] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
  });
  const [disableFileUpload, setDisableFileUpload] = useState(false);
  const [uploadRequestUrl] = useUploadRequestUrlMutation();
  const {
    data: planQuery,
    isError: isGetError,
    error: getError,
  } = useGetPlanQuery(planId, { skip: userId || !planId });

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }

  // TODO Have loading screen, or something better if no data available
  if (!planQuery && planId) {
    return <LoadingSpinner />;
  }

  const { data: planData } = planQuery || {
    data: {},
  };

  console.log(planData);

  const {
    streetAddress1,
    streetAddress2,
    city,
    state,
    country,
    postalCode,
    neighborhood,
  } = planData?.lease?.address || {
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    neighborhood: '',
    postalCode: '',
    state: '',
    country: '',
  };  

  const dateOfBirthFormatted = planData?.dateOfBirth
    ? toDDMMYYYY(new Date(planData.dateOfBirth))
    : '';

  const visaExpirationFormatted = planData?.visaExpiration
    ? toDDMMYYYY(new Date(planData.visaExpiration))
    : '';

  const startLeaseFormatted = planData?.lease?.startDate
    ? toDDMMYYYY(new Date(planData.lease?.startDate))
    : '';
  const endLeaseFormatted = planData?.lease?.endDate
    ? toDDMMYYYY(new Date(planData.lease?.endDate))
    : '';

  const planType =
    planData.status === planStatusEnum.active ? ACTIVE_PLAN : REQUESTED_PLAN;

  const { name: managerName, email: managerEmail } = planData?.managerId || {};
  let filterValues = [
    planStatusEnum.created,
    planStatusEnum.cancelled,
    planStatusEnum.review,
    planStatusEnum.pending,
    planStatusEnum.rejected,
    planStatusEnum.active,
  ];

  const planStatusOptions = filterLabelValues(
    allPlanStatusOptions,
    filterValues
  );

  const lastStatusMessage = getLastStatusMessage(planData);

  const isInSchool =
    planData?.currentSchool?.name &&
    planData?.currentSchool?.enrollmentDate &&
    planData?.currentSchool?.graduationDate;

  const user = planData?.user;
  const userDocs = user?.documents;
  const docLength = userDocs?.length;
  let idDocumentUrl = '';

  if (docLength) {
    idDocumentUrl = userDocs[docLength - 1]?.url;
  }

  const hasCancelAnytime =
    planData?.coverage?.cancelAnytime?.payment?.stripe?.id;
  const hasLeaveAnytime =
    planData?.coverage?.leaveAnytime?.payment?.onetime?.stripe?.id ||
    planData?.coverage?.leaveAnytime?.payment?.subscription?.stripe?.id;
  const hasCoverage = hasCancelAnytime || hasLeaveAnytime;

  return (
    <DashboardWrapperLayout
      title={planData?.id ? `Plan ${planData?.id.toUpperCase()}` : `New Plan`}
    >
      {(isUpdateLoading || isUpdateFetching) && (
        <LoadingSpinner center={true} />
      )}

      {/* Header infomration */}
      <div className='flex flex-col w-full gap-6'>
        {/* Combined breadcrumb + edit toggle header */}
        <div className='w-full flex gap-3 justify-between'>
          {/* breadcrumb */}
          <div className='flex gap-3 items-center'>
            <Link
              to='/dashboard/home'
              className='font-normal text-base text-[#CED0CE]'
            >
              Dashboard
            </Link>
            <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]' />
            <Link
              to='/dashboard/plans'
              className='text-[#CED0CE] text-base font-normal'
            >
              Plans
            </Link>
            <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]' />
            {planData?.id ? (
              <>
                <Link
                  to={`/dashboard/plans/${planData?.id}`}
                  className='text-[#CED0CE] text-base font-normal'
                >
                  {planData?.id.toUpperCase()}
                </Link>
                <span className='font-normal text-base text-[#CED0CE]'>
                  {'>'}
                </span>
                <Link
                  to={`/dashboard/plans/${planData?.id}/edit`}
                  className='text-[#191923] text-base font-normal'
                >
                  Edit
                </Link>
              </>
            ) : (
              <>
                <Link className='text-[#191923] text-base font-normal'>
                  New
                </Link>
              </>
            )}
          </div>

          {/* Don't show toggle if the plan doesn't exist yet */}
          {planData?.id && (
            <Toggle
              selected={'edit'}
              editPath={`/dashboard/plans/${planData?.id}/edit`}
              viewPath={`/dashboard/plans/${planData?.id}`}
            />
          )}
        </div>

        <Formik
          onSubmit={(value) => {
            let valueCp = JSON.parse(JSON.stringify(value));            

            if (!valueCp.visaExpiration) {
              delete valueCp.visaExpiration;
            }

            if (!value.property) {
              valueCp.property = null;
            }

            if (!value.user) {
              valueCp.user = null;
            }

            if (planId) {
              updatePlan({
                id: planData.id,
                ...valueCp,
              })
                .unwrap()
                .then((data) => {
                  navigate(`/dashboard/plans/${data?.id}`);
                })
                .catch((e) => {
                  toast.error(e?.data?.msg || 'Update Failed');
                });
            } else {
              createPlan({
                user: userId,
                property: propertyId,
                ...valueCp,
              })
                .unwrap()
                .then((data) => {
                  navigate(`/dashboard/plans/${data.id}`);
                })
                .catch((e) => {
                  toast.error(e?.data?.msg || 'Create Failed');
                });
            }
          }}
          initialValues={{
            status: planData?.status,
            /* dateOfBirth: toDatePickerFormat(planData?.dateOfBirth), */
            /* relationshipStatus: planData?.relationshipStatus || '', */
            /* residencyStatus: planData?.residencyStatus || '', */
            /* visaExpiration: toDatePickerFormat(planData?.visaExpiration), */
            /* addressesLived: planData?.addressesLived || 0, */
            /* terminatedLeases: planData?.terminatedLeases || 0, */
            /* isActiveMilitary: planData?.isActiveMilitary || false, */
            /* prevSchool: { */
            /*   name: planData?.prevSchool?.name || '', */
            /*   level: planData?.prevSchool?.level || '', */
            /*   graduationDate: toDatePickerFormat( */
            /*     planData?.prevSchool?.graduationDate */
            /*   ), */
            /*   enrollmentDate: toDatePickerFormat( */
            /*     planData?.prevSchool?.enrollmentDate */
            /*   ), */
            /*   degreeName: planData?.prevSchool?.degreeName || '', */
            /* }, */
            /* currentSchool: { */
            /*   name: planData?.currentSchool?.name || '', */
            /*   level: planData?.currentSchool?.level || '', */
            /*   graduationDate: toDatePickerFormat( */
            /*     planData?.currentSchool?.graduationDate */
            /*   ), */
            /*   enrollmentDate: toDatePickerFormat( */
            /*     planData?.currentSchool?.enrollmentDate */
            /*   ), */
            /*   degreeName: planData?.currentSchool?.degreeName || '', */
            /* }, */
            /* jobTurnover: planData?.jobTurnover || 0, */
            /* jobRelocations: planData?.jobRelocations || 0, */
            /* fraudConviction: planData?.fraudConviction || false, */
            /* felonyConviction: planData?.felonyConviction || false, */
            movePlans: planData?.movePlans,
            jobQuitPlans: planData?.jobQuitPlans,
            owner: planData?.owner || null,
            property:
              propertyId || planData?.property?.id || planData?.property || '',
            user: planData?.user?.id || planData?.user || '',
            leaseVerification: planData?.leaseVerification,
            idVerification: planData?.idVerification,
            residencyStatus: planData?.residencyStatus || 'citizenPermanent',
            visaExpiration: planData?.visaExpiration?.split('T')[0] || '',
            rentalHistory: {
              addressesLived: planData?.rentalHistory?.addressesLived,
              terminationHistory: planData?.rentalHistory?.terminationHistory,
              evictionHistory: planData?.rentalHistory?.evictionHistory,
            },
            lease: {
              address: {
                streetAddress1,
                streetAddress2,
                city,
                postalCode,
                neighborhood,
                state,
                country,
              },
              currency: planData?.lease?.currency || null,
              landlordName: planData?.lease?.landlordName,
              monthlyRent: planData?.lease?.monthlyRent,
              document: planData?.lease?.document,
              signDate: toDatePickerFormat(planData?.lease?.signDate),
              startDate: toDatePickerFormat(planData?.lease?.startDate),
              endDate: toDatePickerFormat(planData?.lease?.endDate),
              furnished: planData?.lease?.furnished,
              concessions: {
                repayable: planData?.lease?.concessions?.repayable,
                discount: planData?.lease?.concessions?.discount,
                months: planData?.lease?.concessions?.months,
              },
              terminationPolicy: {
                noticePeriod: planData?.lease?.terminationPolicy?.noticePeriod,
                penalty: planData?.lease?.terminationPolicy?.penalty,
                buyout: planData?.lease?.terminationPolicy?.buyout,
              }
            },
            // NEW this is where you can get the information for coverages
            coverage: {
              [coverageTypes.CANCEL_ANYTIME]: {
                term: planData?.coverage?.[coverageTypes.CANCEL_ANYTIME]?.term,
                startDate:
                  planData?.coverage?.[coverageTypes.CANCEL_ANYTIME]?.startDate,
                endDate:
                  planData?.coverage?.[coverageTypes.CANCEL_ANYTIME]?.endDate,
                payment: {
                  amount:
                    planData?.coverage?.[coverageTypes.CANCEL_ANYTIME]?.payment
                      .amount,
                  rentPercentage:
                    planData?.coverage?.[coverageTypes.CANCEL_ANYTIME]?.payment
                      .rentPercentage,
                  effectiveMonthlyAmount:
                    planData?.coverage?.[coverageTypes.CANCEL_ANYTIME]?.payment
                      .effectiveMonthlyAmount,
                  stripe:
                    planData?.coverage?.[coverageTypes.CANCEL_ANYTIME]?.payment
                      .stripe,
                },
              },
              [coverageTypes.LEAVE_ANYTIME]: {
                term: planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]?.term,
                startDate:
                  planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]?.startDate,
                endDate:
                  planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]?.endDate,
                payment: {
                  [planPaymentMode.ONE_TIME]: {
                    amount:
                      planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]?.payment
                        .amount,
                    rentPercentage:
                      planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]?.payment
                        .rentPercentage,
                    discountPercentage:
                      planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]?.payment
                        .discountPercentage,
                    effectiveMonthlyAmount:
                      planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]?.payment
                        .effectiveMonthlyAmount,
                    stripe:
                      planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]?.payment
                        .stripe,
                  },
                  [planPaymentMode.SUBSCRIPTION]: {
                    amount:
                      planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]?.payment
                        .amount,
                    rentPercentage:
                      planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]?.payment
                        .rentPercentage,
                    stripe:
                      planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]?.payment
                        .stripe,
                  },
                },
              },
            },
          }}
          validationSchema={Yup.object().shape({
            status: Yup.string().optional(),
            rejectedMessage: Yup.string().optional(),
            property: Yup.string().test(
              'is-empty-or-24-chars',
              'Must be an empty string or exactly 24 characters long',
              (value) =>
                value === '' ||
                value === null ||
                value === undefined ||
                (value && value.length === 24)
            ),  
            residencyStatus: Yup.string().required(),
            visaExpiration: Yup.date(),
            user: Yup.string().test(
              'is-empty-or-24-chars',
              'Must be an empty string or exactly 24 characters long',
              (value) =>
                value === '' ||
                value === null ||
                value === undefined ||
                (value && value.length === 24)
            ),
          })}
        >
          {({
            handleSubmit,
            handleBlur,
            handleChange,
            isValid,
            dirty: isDirty,
            values,
            setFieldValue,
          }) => {
            return (
              <Form className='flex flex-col w-full gap-6'>
                {/* Action Buttons */}
                <div className='w-full flex gap-2 justify-end items-center'>
                  <Button
                    disabled={!isValid || !isDirty}
                    onClick={handleSubmit}
                    title='Save Changes'
                    wFull={false}
                    className='px-6'
                  />
                  {values.owner ? (
                    <Button
                      theme='secondary'
                      type='button'
                      title='Remove Building Owner'
                      onClick={() => {
                        setFieldValue('owner', null);
                      }}
                      wFull={false}
                      className='px-6'
                    />
                  ) : (
                    <span />
                  )}
                </div>

                {/* Progress Bar */}
                {planData?.status !== statusEnum.active && (
                  <ProgressBar>
                    <ProgressBarStep
                      title='Property Selected'
                      complete={planData?.property}
                    />
                    <ProgressBarStep title='Member Added' complete={planData?.user} />
                    <ProgressBarStep title='Lease Added' complete={planData?.lease} />
                    <ProgressBarStep title='Coverage Added' complete={hasCoverage} />
                    <ProgressBarStep
                      title='Ready for Review'
                      complete={planData?.status === statusEnum.review}
                    />
                  </ProgressBar>
                )}

                <InfoCard title='Plan Information'>
                  <InfoGrid>
                    <InfoField title='Plan Status'>
                      <SelectInput
                        options={planStatusOptions}
                        placeholder='Select Status'
                        name='status'
                        value={planStatusOptions.find(
                          (status) => status.value === values.status
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue('status', selectedOption?.value || '');
                        }}
                        onBlur={handleBlur}
                      />
                    </InfoField>
                    <FormError name='status' />
                  </InfoGrid>                  
                </InfoCard>

                <InfoCard title='Property Information'>
                  <InfoGrid>
                    <InfoField title='Property' className='col-span-4'>
                      <SelectInput
                        options={createPropertyOptionMenu(propertiesData)}
                        isDisabled={
                          isEmpty(propertiesData) || findPropertiesLoading
                        }
                        isSearchable={false}
                        placeholder={
                          findPropertiesLoading
                            ? 'Fetching...'
                            : isEmpty(propertiesData)
                            ? `No available buildings`
                            : 'Select building from the list'
                        }
                        name='property'
                        value={createPropertyOptionMenu(propertiesData).find(
                          (selectedProperty) =>
                            selectedProperty.value === values.property
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            'property',
                            selectedOption?.value || ''
                          );
                        }}
                        onBlur={handleBlur}
                      />
                      <FormError name='property' />
                    </InfoField>
                  </InfoGrid>
                </InfoCard>

                <InfoCard title='Member Information'>
                  <InfoGrid>
                    <InfoField title='Member' className='col-span-4'>
                      <SelectInput
                        options={createTenantOptionMenu(tenantsData)}
                        isDisabled={
                          isEmpty(tenantsData) || findTenantsLoading
                        }
                        isSearchable={false}
                        placeholder={
                          findTenantsLoading
                            ? 'Fetching...'
                            : isEmpty(tenantsData)
                            ? `No available members`
                            : 'Select member from the list'
                        }
                        name='user'
                        value={createTenantOptionMenu(tenantsData).find(
                          (selectedTenant) =>
                            selectedTenant.value === values.user
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            'user',
                            selectedOption?.value || ''
                          );
                        }}
                        onBlur={handleBlur}
                      />
                      <FormError name='user' />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Job Quit Premeditated'>
                      <SelectInput
                        options={booleanOptions}
                        placeholder='Select Option'
                        name='jobQuitPlans'
                        value={booleanOptions.find(
                          (status) => status.value === values.jobQuitPlans
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue('jobQuitPlans', selectedOption?.value || false);
                        }}
                        onBlur={handleBlur}
                      />
                      <FormError name='jobQuitPlans' />
                    </InfoField>
                    <InfoField title='Moving Premeditated'>
                      <SelectInput
                        options={booleanOptions}
                        placeholder='Select Option'
                        name='movePlans'
                        value={booleanOptions.find(
                          (status) => status.value === values.movePlans
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue('movePlans', selectedOption?.value || false);
                        }}
                        onBlur={handleBlur}
                      />
                      <FormError name='movePlans' />
                    </InfoField>                    
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Residency Status' className='col-span-2'>
                      <SelectInput
                        options={residencyStatusOptions}
                        placeholder='Select Option'
                        name='residencyStatus'
                        value={residencyStatusOptions.find(
                          (status) => status.value === values.residencyStatus
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue('residencyStatus', selectedOption?.value || '');
                        }}
                        onBlur={handleBlur}
                      />
                      <FormError name='residencyStatus' />
                    </InfoField>
                    {values.residencyStatus !== 'citizenPermanent' && (
                      <InfoField title='Visa Expiration Date'>
                        <Input
                          type='date'
                          value={values.visaExpiration}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name='visaExpiration'
                        />
                        <FormError name='visaExpiration' />
                      </InfoField>
                    )}
                  </InfoGrid>
                </InfoCard>

                <InfoCard title='Past 3 Years Rental History'>
                  <InfoGrid>
                    <InfoField title='Addressed Lived'>
                      <Input
                        type='number'
                        placeholderText='Enter Number'
                        value={values.rentalHistory.addressesLived}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='rentalHistory.addressesLived'
                        suffix='addresses'
                      />
                      <FormError name='rentalHistory.addressesLived' />
                    </InfoField>
                    <InfoField title='Termination History (> 2)'>
                      <SelectInput
                        options={booleanOptions}
                        placeholder='Select Option'
                        name='rentalHistory.terminationHistory'
                        value={booleanOptions.find(
                          (status) => status.value === values.rentalHistory.terminationHistory
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue('rentalHistory.terminationHistory', selectedOption?.value || false);
                        }}
                        onBlur={handleBlur}
                      />
                      <FormError name='rentalHistory.terminationHistory' />
                    </InfoField>
                    <InfoField title='Eviction History (> 1)'>
                      <SelectInput
                        options={booleanOptions}
                        placeholder='Select Option'
                        name='rentalHistory.evictionHistory'
                        value={booleanOptions.find(
                          (status) => status.value === values.rentalHistory.evictionHistory
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue('rentalHistory.evictionHistory', selectedOption?.value || false);
                        }}
                        onBlur={handleBlur}
                      />
                      <FormError name='rentalHistory.evictionHistory' />
                    </InfoField>
                  </InfoGrid>
                </InfoCard>                

                <InfoCard title='Lease Information'>
                  <InfoGrid>
                    <InfoField title='Autofill Address' className='col-span-4'>
                      <AutoCompleteAddress
                        onAddressChange={(newAddress) => {
                          setFieldValue(
                            'lease.address.streetAddress1',
                            newAddress.streetAddress1 || ''
                          );
                          setFieldValue(
                            'lease.address.streetAddress2',
                            newAddress.streetAddress2 || ''
                          );
                          setFieldValue(
                            'lease.address.city',
                            newAddress.city || ''
                          );
                          setFieldValue(
                            'lease.address.state',
                            newAddress.state || ''
                          );
                          setFieldValue(
                            'lease.address.country',
                            newAddress.country || ''
                          );
                          setFieldValue(
                            'lease.address.postalCode',
                            newAddress.zipCode || ''
                          );
                        }}
                      />
                    </InfoField>
                    <InfoField title='Street Address 1' className='col-span-2'>
                      <Input
                        placeholderText='Street Address 1'
                        value={values.lease.address.streetAddress1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.address.streetAddress1'
                      />
                      <FormError name='lease.address.streetAddress1' />
                    </InfoField>
                    <InfoField title='Street Address 2' className='col-span-2'>
                      <Input
                        placeholderText='Street Address 2'
                        value={values.lease.address.streetAddress2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.address.streetAddress2'
                      />
                      <FormError name='lease.address.streetAddress2' />
                    </InfoField>
                    <InfoField title='City'>
                      <Input
                        placeholderText='City'
                        value={values.lease.address.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.address.city'
                      />
                      <FormError name='lease.address.city' />
                    </InfoField>
                    <InfoField title='State'>
                      <Input
                        placeholderText='State'
                        value={values.lease.address.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.address.state'
                      />
                      <FormError name='lease.address.state' />
                    </InfoField>
                    <InfoField title='Postal Code'>
                      <Input
                        placeholderText='Postal Code'
                        value={values.lease.address.postalCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.address.postalCode'
                      />
                      <FormError name='lease.address.postalCode' />
                    </InfoField>
                    <InfoField title='Country'>
                      <Input
                        placeholderText='Country'
                        value={values.lease.address.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.address.country'
                      />
                      <FormError name='lease.address.country' />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Signing Date'>
                      <Input
                        type='date'
                        value={values.lease.signDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.signDate'
                      />
                      <FormError name='lease.signDate' />
                    </InfoField>
                    <InfoField title='Start Date'>
                      <Input
                        type='date'
                        value={values.lease.startDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.startDate'
                      />
                      <FormError name='lease.startDate' />
                    </InfoField>
                    <InfoField title='End Date'>
                      <Input
                        type='date'
                        value={values.lease.endDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.endDate'
                      />
                      <FormError name='lease.endDate' />
                    </InfoField>                    
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Landlord Name'>
                      <Input
                        placeholderText='Name'
                        value={values.lease.landlordName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.landlordName'
                      />
                      <FormError name='lease.landlordName' />
                    </InfoField>    
                    <InfoField title='Monthly Rent'>
                      <Input
                        type='number'
                        placeholderText='Enter Number'
                        value={values.lease.monthlyRent}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.monthlyRent'
                        prefix='$'
                        suffix={values.lease.currency}
                      />
                      <FormError name='lease.monthlyRent' />
                    </InfoField>
                    <InfoField title='Currency'>
                      <SelectInput
                        options={planCurrencyOptions}
                        placeholder='Select Currency'
                        name='lease.currency'
                        value={planCurrencyOptions.find(
                          (status) => status.value === values.lease.currency
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            'lease.currency',
                            selectedOption?.value || ''
                          );
                        }}
                        onBlur={handleBlur}
                      />
                    </InfoField>
                    <InfoField title='Furnished?'>
                      <SelectInput
                        options={booleanOptions}
                        placeholder='Select Option'
                        name='lease.furnished'
                        value={booleanOptions.find(
                          (status) => status.value === values.lease.furnished
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue('lease.furnished', selectedOption?.value || false);
                        }}
                        onBlur={handleBlur}
                      />
                      <FormError name='lease.furnished' />
                    </InfoField>
                    <InfoField title='Repayable Concessions'>
                      <SelectInput
                        options={booleanOptions}
                        placeholder='Select Option'
                        name='lease.concessions.repayable'
                        value={booleanOptions.find(
                          (status) => status.value === values.lease.concessions.repayable
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue('lease.concessions.repayable', selectedOption?.value || false);
                        }}
                        onBlur={handleBlur}
                      />
                      <FormError name='lease.concessions.repayable' />
                    </InfoField>
                    <InfoField title='Concession Discount'>
                      <Input
                        type='number'
                        placeholderText='Enter Number'
                        value={values.lease.concessions.discount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.concessions.discount'
                        suffix='/ 1 Off Rent'                    
                      />
                      <FormError name='lease.concessions.discount' />
                    </InfoField>
                    <InfoField title='Concession Months'>
                      <Input
                        type='number'
                        placeholderText='Enter Number'
                        value={values.lease.concessions.months}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.concessions.months'
                        suffix='Months'
                      />
                      <FormError name='lease.concessions.months' />
                    </InfoField>
                  </InfoGrid>            
                </InfoCard>
                
                {/* Uploaded Lease and Termination Policy */}
                <InfoCard title='Lease Agreement'>
                  <InfoGrid>
                    <InfoField title='Lease Verification'>
                      <SelectInput
                        options={documentStatusOptions}
                        placeholder='Select Status'
                        name='leaseVerification'
                        value={documentStatusOptions.find(
                          (status) => status.value === values.leaseVerification
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            'leaseVerification',
                            selectedOption?.value || ''
                          );
                        }}
                        onBlur={handleBlur}
                      />
                    </InfoField>
                    <InfoField title='Upload New Lease'>
                      <FileUploadInput
                        disableUpload={disableFileUpload}
                        isUploadError={uploadStatus.isError}
                        isUploadLoading={uploadStatus.isLoading}
                        isUploadSuccess={
                          uploadStatus.isSuccess ||
                          !isEmpty(planData?.lease?.document)
                        }
                        handlePDFClear={() => {
                          setFieldValue('lease.document', '');
                        }}
                        handlePDFUpload={async (file) => {
                          let uploadUrlReq;
                          setUploadStatus({
                            isSuccess: false,
                            isLoading: true,
                            isError: false,
                          });

                          // Get the signing url for uploading to s3
                          try {
                            uploadUrlReq = await uploadRequestUrl({
                              fileName: file.name,
                              contentType: file.type,
                            }).unwrap();
                          } catch (e) {
                            setUploadStatus({
                              isSuccess: false,
                              isError: true,
                              isLoading: false,
                            });
                            toast.error(
                              e?.msg ||
                                e?.message ||
                                'failed to upload document'
                            );
                            return;
                          }

                          if (
                            !uploadUrlReq?.data?.uploadUrl ||
                            !uploadUrlReq?.data?.viewUrl
                          ) {
                            setUploadStatus({
                              isSuccess: false,
                              isError: true,
                              isLoading: false,
                            });
                            toast.error('failed to upload document');
                          }

                          // Upload the file to s3

                          try {
                            await uploadFileToS3({
                              s3Url: uploadUrlReq?.data?.uploadUrl,
                              file,
                            });

                            // Have to set the uploaded file url on the lease document
                            setUploadStatus({
                              isSuccess: true,
                              isError: false,
                              isLoading: false,
                            });
                            setDisableFileUpload(true);
                            setFieldValue(
                              'lease.document',
                              uploadUrlReq?.data?.viewUrl
                            );
                            toast.info(
                              uploadUrlReq?.status ||
                                'file has been uploaded successfully'
                            );
                          } catch (e) {
                            setUploadStatus({
                              isSuccess: false,
                              isError: true,
                              isLoading: false,
                            });
                            toast.error(
                              e?.msg ||
                                e?.message ||
                                'failed to upload document'
                            );
                            return;
                          }
                        }}
                      />
                      <FormError name='lease.document' />
                    </InfoField>
                    <InfoField title='Uploaded Lease' className='col-span-2'>
                      <DocumentPreview
                        label={extractDocumentName(planData?.lease?.document)}
                        url={planData?.lease?.document}
                      />
                    </InfoField>                  
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Termination Notice Period'>
                      <Input
                        type='number'
                        placeholderText='Enter Number'
                        value={values.lease.terminationPolicy.noticePeriod}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.terminationPolicy.noticePeriod'
                        suffix='Days'
                      />
                      <FormError name='lease.terminationPolicy.noticePeriod' />
                    </InfoField>
                    <InfoField title='Termination Penalty'>
                      <Input
                        type='number'
                        placeholderText='Enter Number'
                        value={values.lease.terminationPolicy.penalty}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.terminationPolicy.penalty'
                        prefix='$'
                        suffix={planData?.lease?.currency}
                      />
                      <FormError name='lease.terminationPolicy.penalty' />
                    </InfoField>
                    <InfoField title='Termination Buyout'>
                      <Input
                        type='number'
                        placeholderText='Enter Number'
                        value={values.lease.terminationPolicy.buyout}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.terminationPolicy.buyout'
                        prefix='$'
                        suffix={planData?.lease?.currency}
                      />
                      <FormError name='lease.terminationPolicy.buyout' />
                    </InfoField>
                  </InfoGrid>
                </InfoCard>
              </Form>
            );
          }}
        </Formik>
      </div>
    </DashboardWrapperLayout>
  );
};

export default PlanDetails;
