import { Link, useParams } from 'react-router-dom';
import { InfoCard, InfoField, InfoGrid } from 'common/components/info-card';
import { IoMdSave } from 'react-icons/io';
import LoadingSpinner from 'components/shared/loading-spinner';
import ErrorData from 'components/shared/error-data';
import * as Yup from 'yup';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { Form, Formik, useFormikContext } from 'formik';
import deepGet from 'utils/deepGet';
import AutoCompleteAddress from 'components/shared/autoaddress';
import Input from 'components/shared/input';
import SelectInput from 'common/components/select-input';
import {
  rentalTypeEnum,
  propertyKindEnum,
  propertyStatusEnum,
} from 'utils/enums';
import {
  propertyKindOptions,
  propertyStatusOptions,
} from 'utils/selectOptions';
import FormError from 'components/shared/form-error';
import {
  useGetPropertyQuery,
  useNewPropertyMutation,
  useUpdatePropertyMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from 'common/components/button';
import { PhoneNumberInput } from 'common/components/phone-input';
import 'react-international-phone/style.css';
import Toggle from 'components/shared/toggle';
import TextAreaInput from 'components/shared/text-area-input';
import { Icon } from '@chakra-ui/react';
import { FaChevronRight } from 'react-icons/fa6';

function AutoCompleteAddressFormikWrapper({ name, placeholder }) {
  const { values, setFieldValue } = useFormikContext();
  const pathToAddress = `${name}`;

  const initialAddress = deepGet(values, pathToAddress);
  const handleAddressChange = (newAddress) => {
    setFieldValue(pathToAddress, newAddress);
  };

  return (
    <AutoCompleteAddress
      initialAddress={initialAddress}
      onAddressChange={handleAddressChange}
      placeholder={placeholder}
    />
  );
}

function View() {
  const { propertyId } = useParams();
  const isNew = !propertyId;
  const navigate = useNavigate();
  const {
    data: propertyData,
    isError: isGetError,
    error: getError,
    isLoading: isLoadingProperty,
    isFetching: isFetchingProperty,
  } = useGetPropertyQuery(propertyId, { skip: isNew });
  const [updateProperty, { isLoading: isUpdatingProperty }] =
    useUpdatePropertyMutation();
  const [
    createProperty,
    { isLoading: isCreateLoading, isFetching: isCreateFetching },
  ] = useNewPropertyMutation();

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }

  const {
    streetAddress1,
    streetAddress2,
    city,
    state,
    country,
    postalCode,
    neighborhood,
  } = propertyData?.address || {
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    neighborhood: '',
    postalCode: '',
    state: '',
    country: '',
  };

  const queryParams = new window.URLSearchParams(window.location.search);
  const ownerId = queryParams.get('owner')

  if (!isNew && !propertyData) return <LoadingSpinner />;

  console.log(propertyData);
  console.log('Loading?', isUpdatingProperty);

  return (
    <Formik
      onSubmit={(values) => {
        let valuesCp = JSON.parse(JSON.stringify(values));

        if (!values.owner) {
          valuesCp.owner = null;
        }

        if (propertyId) {
          updateProperty({
            id: propertyId,
            ...valuesCp,            
          })
            .unwrap()
            .then(() => {              
              navigate(`/dashboard/properties/${propertyId}`);
            })
            .catch((e) => {
              toast.error(e?.data?.msg || 'Update Failed');
            });
        } else {
          createProperty({
            ...valuesCp,
          })
            .unwrap()
            .then((prop) => {
              navigate(`/dashboard/properties/${prop?.id}`);
            });
        }
      }}
      initialValues={{
        name: propertyData?.name,
        owner: ownerId || propertyData?.owner?.id || propertyData?.owner || '',
        phone: propertyData?.phone || '',
        email: propertyData?.email || '',
        website: propertyData?.website || '',
        notes: propertyData?.notes || '',
        status: propertyData?.status || propertyStatusEnum.REQUIRED,
        kind: propertyData?.kind || propertyKindEnum.OTHER,
        riskScore: propertyData?.riskScore || 0,
        cancelAnytimeRate: propertyData?.cancelAnytimeRate,
        leaveAnytimeRate: propertyData?.leaveAnytimeRate,
        address: {
          streetAddress1,
          streetAddress2,
          city,
          postalCode,
          neighborhood,
          state,
          country,
        },
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Building name is required'),
        owner: Yup.string().test(
          'is-empty-or-24-chars',
          'Must be an empty string or exactly 24 characters long',
          (value) =>
            value === '' ||
            value === null ||
            value === undefined ||
            (value && value.length === 24)
        ),
        email: Yup.string(),
        phone: Yup.string(),
        website: Yup.string(),
        notes: Yup.string(),
        riskScore: Yup.number(),
        cancelAnytimeRate: Yup.number('Default rate must be a number'),
        leaveAnytimeRate: Yup.number('Default rate must be a number'),
      })}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        isValid,
        values,
        setFieldValue,
        dirty: isDirty,
      }) => {
        return (
          <Form>
            <DashboardWrapperLayout title={propertyData?.name || 'Property Details'}>
              {/* Loading animation */}
              {(isLoadingProperty ||
                isUpdatingProperty ||
                isFetchingProperty) && <LoadingSpinner center='true' />
              }
              
              <div className='flex flex-col w-full gap-6'>
                
                {/* Combined breadcrumb + edit toggle header */}
                <div className='w-full flex gap-3 justify-between'>
                  {/* breadcrumb */}
                  <div className='flex gap-3 items-center'>
                    <Link
                      to='/dashboard/home'
                      className='font-normal text-base text-[#CED0CE]'
                    >
                      Dashboard
                    </Link>
                    <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
                    <Link
                      to='/dashboard/properties'
                      className='text-[#CED0CE] text-base font-normal'
                    >
                      Properties{' '}
                    </Link>
                    <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
                    {
                      propertyData?.id  
                      ? (<>
                          <Link
                            to={`/dashboard/properties/${propertyData?.id}`}
                            className='text-[#CED0CE] text-base font-normal'
                          >
                            {propertyData?.id.toUpperCase()}
                          </Link>
                          <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
                          <Link to={`/dashboard/properties/${propertyData?.id}/edit`} className='text-[#191923] text-base font-normal'>
                            Edit{' '}
                          </Link>
                        </>)
                      : (<>
                        <Link to='' className='text-[#191923] text-base font-normal'>
                            New{' '}
                          </Link>
                      </>)
                    }
                    
                  </div>

                  <Toggle
                    selected={'edit'}
                    editPath={`/dashboard/properties/${propertyData?.id}/edit`}
                    viewPath={`/dashboard/properties/${propertyData?.id}`}
                  />
                </div>

                {/* Action Buttons */}
                <div className='w-full flex gap-2 justify-end items-center'>
                  <Button
                    disabled={!isValid || !isDirty}
                    onClick={handleSubmit}
                    title='Save Changes'
                    wFull={false}
                    className='px-6'
                  />
                </div>

                {/* Property Fields Table */}
                <InfoCard title='Property Information'>
                  <InfoGrid>
                    <InfoField title='Name'>
                      <Input
                        placeholderText='Name'
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='name'
                      />
                      <FormError name='name' />
                    </InfoField>
                    <InfoField title='Status'>
                      <SelectInput
                        options={propertyStatusOptions}
                        placeholder='Select Status'
                        name='status'
                        value={propertyStatusOptions.find(
                          (status) => status.value === values.status
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            'status',
                            selectedOption?.value || ''
                          );
                        }}
                        onBlur={handleBlur}
                      />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Contact Email'>
                      <Input
                        placeholderText='Email'
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='email'
                      />
                      <FormError name='email' />
                    </InfoField>
                    <InfoField title='Contact Phone'>
                      <PhoneNumberInput
                        name='phone'
                        value={values.phone}
                        onChange={(e) => {
                          setFieldValue('phone', e);
                        }}
                        onBlur={handleBlur}
                      />
                      <FormError name='phone' />
                    </InfoField>
                    <InfoField title='Website'>
                      <Input
                        placeholderText='website'
                        type='website'
                        value={values.website}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='website'
                      />
                      <FormError name='website' />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Cancel Anytime Default Rate'>
                      <Input
                        type='number'
                        placeholderText='Cancel Anytime Default Risk'
                        value={values.cancelAnytimeRate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='cancelAnytimeRate'
                      />
                      <FormError name='cancelAnytimeRate' /> 
                    </InfoField>
                    <InfoField title='Leave Anytime Default Rate'>
                      <Input
                        type='number'
                        placeholderText='Leave Anytime Default Risk'
                        value={values.leaveAnytimeRate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='leaveAnytimeRate'
                      />
                      <FormError name='leaveAnytimeRate' /> 
                    </InfoField>
                    <InfoField title='Risk Score'>
                      <Input
                        type='number'
                        placeholderText='Risk Score'
                        value={values.riskScore}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='riskScore'
                      />
                      <FormError name='riskScore' />
                    </InfoField>
                    <InfoField title='Property Type'>
                      <SelectInput
                        options={propertyKindOptions}
                        placeholder='Select Type'
                        name='kind'
                        value={propertyKindOptions.find(
                          (kind) => kind.value === values.kind
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue('kind', selectedOption?.value || '');
                        }}
                        onBlur={handleBlur}
                      />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Autocomplete Address' className='col-span-4'>
                      <AutoCompleteAddress
                        onAddressChange={(newAddress) => {
                          setFieldValue(
                            'address.streetAddress1',
                            newAddress.streetAddress1 || ''
                          );
                          setFieldValue(
                            'address.streetAddress2',
                            newAddress.streetAddress2 || ''
                          );
                          setFieldValue(
                            'address.city',
                            newAddress.city || ''
                          );
                          setFieldValue(
                            'address.state',
                            newAddress.state || ''
                          );
                          setFieldValue(
                            'address.country',
                            newAddress.country || ''
                          );
                          setFieldValue(
                            'address.postalCode',
                            newAddress.zipCode || ''
                          );
                        }}
                      />
                    </InfoField>
                    <InfoField
                      title='Street Address 1'
                      className='col-span-2'
                    >
                      <Input
                        placeholderText='Street Address 1'
                        value={values.address.streetAddress1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='address.streetAddress1'
                      />
                      <FormError name='address.streetAddress1' />
                    </InfoField>
                    <InfoField
                      title='Street Address 2'
                      className='col-span-2'
                    >
                      <Input
                        placeholderText='Street Address 2'
                        value={values.address.streetAddress2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='address.streetAddress2'
                      />
                      <FormError name='address.streetAddress2' />
                    </InfoField>
                    <InfoField title='City'>
                      <Input
                        placeholderText='City'
                        value={values.address.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='address.city'
                      />
                      <FormError name='address.city' />
                    </InfoField>
                    <InfoField title='State'>
                      <Input
                        placeholderText='State'
                        value={values.address.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='address.state'
                      />
                      <FormError name='address.state' />
                    </InfoField>
                    <InfoField title='Postal Code'>
                      <Input
                        placeholderText='Postal Code'
                        value={values.address.postalCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='address.postalCode'
                      />
                      <FormError name='address.postalCode' />
                    </InfoField>
                    <InfoField title='Country'>
                      <Input
                        placeholderText='Country'
                        value={values.address.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='address.country'
                      />
                      <FormError name='address.country' />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Notes' className='col-span-4'>
                      <TextAreaInput
                        placeholderText='notes'
                        type='notes'
                        value={values.notes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='notes'
                      />
                      <FormError name='notes' />
                    </InfoField>
                  </InfoGrid>
                </InfoCard>

                {/* Management and Owner Information */}
                <InfoCard title='Management Information'>
                  <InfoGrid>
                    <InfoField title='Company ID'>
                      <Input
                        placeholderText='Company ID'
                        value={values.owner}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='owner'
                      />
                      <FormError name='owner' />
                    </InfoField>
                  </InfoGrid>
                </InfoCard>
              </div>
            </DashboardWrapperLayout>
          </Form>
        );
      }}
    </Formik>
  );
}

export default View;
