import React from 'react';

const TextAreaInput = ({
  placeholderText = '',
  name = '',
  onChange,
  value,
  onFocus,
  onBlur,
  disabled,
  className
}) => {
  return (
    <textarea
      placeholder={placeholderText}
      name={name}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
      className={`h-full w-full border-solid border border-[#CED0CE] rounded-md bg-white text-base text-[#191923] p-3 font-normal placeholder:text-[#6A6C70] disabled:bg-inherit disabled:opacity-60 ${className}`}
    />
  );
};

export default TextAreaInput;
