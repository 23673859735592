import React from 'react';

const Input = ({
  Icon,
  placeholderText = '',
  disableIcon = false,
  name = '',
  type = 'text',
  onChange,
  value,
  onFocus,
  onBlur,
  disabled,
  prefix,
  suffix,
  className,
}) => {
  return (
    <div className={`flex items-center justify-between w-full border-solid border border-[#CED0CE] rounded-md bg-white h-10 px-3 gap-x-3 ${disabled && 'bg-slate-100 opacity-60'} ${className}`}>
      {/* icon */}
      {disableIcon || !Icon ? (
        <></>
      ) : (
        <Icon className='text-[#6A6C70]' />
      )}

      {/* prefix */}
      {prefix && <span className='text-[#6A6C70]'>{prefix}</span>}

      {/* input */}
      <input
        placeholder={placeholderText}
        name={name}
        value={value}
        type={type}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        className={`h-full w-full border-none [outline:none] text-base text-[#191923] font-normal placeholder:text-[#6A6C70] ${suffix && 'text-right'} disabled:bg-inherit disabled:opacity-60`}
      />

      {/* suffix */}
      {suffix && <span className='shrink-0 text-[#6A6C70]'>{suffix}</span>}
    </div>
  );
};

export default Input;
