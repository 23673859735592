import React from 'react';
import { BiEdit } from 'react-icons/bi';
import { FaRegCircleUser } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import StatusLabel from 'components/claims/status-label';
import ClaimType from 'components/claims/claim-type';
import SortButton from 'common/components/sort-button';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Link,
} from '@chakra-ui/react';
import {
  ViewIcon,
  AddIcon,
  RepeatIcon,
  LockIcon,
  CloseIcon,
  TriangleDownIcon,
  TriangleUpIcon,
  HamburgerIcon,
  CopyIcon,
} from '@chakra-ui/icons';
import {
  Table,
  HeaderRow,
  HeaderCell,
  TableBody,
  BodyRow,
  TableCell,
} from 'common/components/data-table';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';

const ClaimsTable = ({ claims, isRounded = true, sortVal, setSort }) => {
  // DATA INITIALIZATION
  const navigate = useNavigate();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {},
      (err) => {
        console.error('Failed to copy text: ', err);
      }
    );
  };

  return (
    <Table>
      {/* head */}
      <HeaderRow
        isRounded={isRounded}
        gridLayout={'grid-cols-[220px_1fr_200px_120px_200px]'}
      >
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='submissionAt'
            label='Claim Number'
          />
        </HeaderCell>
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='planId'
            label='Plan'
          />
        </HeaderCell>
        <HeaderCell>
          Member
        </HeaderCell>
        <HeaderCell>
          Cancellation
        </HeaderCell>
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='status'
            label='Status'
          />
        </HeaderCell>
      </HeaderRow>

      {/* body */}
      <TableBody>
        {claims.map((claim, index) => (
          <BodyRow
            key={index}
            onClick={() => {
              navigate(`/dashboard/claims/${claim.id}`);
            }}
            gridLayout={
              'grid-cols-[220px_1fr_200px_120px_200px]'
            }
          >
            {/* Claim Number and Submitted Date */}
            <TableCell
              top={claim?.id}
              bottom={`Submitted ${formatDateToHumanDate(claim?.submissionAt)}`}
            />

            {/* Plan */}
            <TableCell
              top={`${claim?.planId?.lease?.address?.streetAddress1}, ${claim?.planId?.lease?.address?.city}, ${claim?.planId?.lease?.address?.state}`}
              bottom={claim?.planId?.id}
            />

            {/* Member */}
            <TableCell
              top={claim?.userId?.name || `${claim?.userId?.firstName} ${claim?.userId?.lastName}`}
              bottom={claim?.userId?.email}
            />

            {/* Cancellation */}
            <TableCell
              top={formatDateToHumanDate(claim?.moveOutDate)}
              bottom={claim?.claimType}
            />

            {/* Status and Edit button */}
            <TableCell
              className='justify-between'
              onClick={(e) => {
                e.stopPropagation();
                return false;
              }}
            >
              <StatusLabel size='sm' status={claim?.status} />
              
              {/* Menu button */}
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label='Options'
                  icon={<HamburgerIcon />}
                  variant='outline'
                />
                <MenuList>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/claims/${claim?.id}`);
                    }}
                    icon={<ViewIcon />}
                  >
                    View Claim Details
                  </MenuItem>                  
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipboard(claim?.id);
                    }}
                    icon={<CopyIcon />}
                  >
                    Copy Claim Number
                  </MenuItem>
                </MenuList>
              </Menu>
            </TableCell>
          </BodyRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ClaimsTable;
