import {
  ownerStatusEnum,
  propertyStatusEnum,
  tenantStatusEnum,
  documentStatusEnum,
  propertyKindEnum,
  ownerKindEnum,
  planPaymentEnum,
  verificationStatusEnum,
} from 'utils/enums';

export const tenantStatusOptions = [
  { label: tenantStatusEnum.NEW, value: tenantStatusEnum.NEW },
  { label: tenantStatusEnum.INVITED, value: tenantStatusEnum.INVITED },
  { label: tenantStatusEnum.ACCEPTED, value: tenantStatusEnum.ACCEPTED },
  { label: tenantStatusEnum.DELETED, value: tenantStatusEnum.DELETED },
];

export const ownerStatusOptions = [
  { label: ownerStatusEnum.ACTIVE, value: ownerStatusEnum.ACTIVE },
  { label: ownerStatusEnum.DELETED, value: ownerStatusEnum.DELETED },
];

export const ownerKindOptions = [
  { label: ownerKindEnum.OWNER, value: ownerKindEnum.OWNER },
  { label: ownerKindEnum.MANAGER, value: ownerKindEnum.MANAGER },
];

export const propertyStatusOptions = [
  { label: propertyStatusEnum.INACTIVE, value: propertyStatusEnum.INACTIVE },
  { label: propertyStatusEnum.OPTOUT, value: propertyStatusEnum.OPTOUT },
  { label: propertyStatusEnum.OPTIN, value: propertyStatusEnum.OPTIN },
  { label: propertyStatusEnum.REQUIRED, value: propertyStatusEnum.REQUIRED },
];

export const propertyKindOptions = [
  {
    label: propertyKindEnum.MULTIFAMILY,
    value: propertyKindEnum.MULTIFAMILY,
  },
  {
    label: propertyKindEnum.STUDENT,
    value: propertyKindEnum.STUDENT,
  },
  {
    label: propertyKindEnum.OTHER,
    value: propertyKindEnum.OTHER,
  },
];

export const documentStatusOptions = [
  { label: 'None', value: documentStatusEnum.NONE },
  { label: 'Uploaded', value: documentStatusEnum.UPLOADED },
  { label: 'Rejected', value: documentStatusEnum.REJECTED },
  { label: 'Verified', value: documentStatusEnum.VERIFIED },
];

export const verificationStatusOptions = [
  { label: 'Verified', value: verificationStatusEnum.VERIFIED },
  { label: 'Not Verified', value: verificationStatusEnum.NOT_VERIFIED },
];

export const adminStatusOptions = [
  {
    label: 'Submitted',
    value: 'Submitted',
  },
  {
    label: 'Completed',
    value: 'Completed',
  },
  {
    label: 'In Review',
    value: 'In Review',
  },
  {
    label: 'Expired',
    value: 'Expired',
  },
  {
    label: 'Denied',
    value: 'Denied',
  },
  {
    label: 'Approved',
    value: 'Approved',
  },
  {
    label: 'Confirmed',
    value: 'Confirmed',
  },
];

export const relationshipStatusOptions = [
  {
    label: 'Single',
    value: 'single',
  },
  {
    label: 'Married',
    value: 'married',
  },
  {
    label: 'Common law',
    value: 'commonLaw',
  },
  {
    label: 'Non-common law cohabitating relationship',
    value: 'nonCommonCohab',
  },
];
export const residencyStatusOptions = [
  {
    label: 'Citizen or Permanent Resident',
    value: 'citizenPermanent',
  },
  {
    label: 'Employer-Sponsored Work Visa (i.e. Closed)',
    value: 'employerVisa',
  },
  {
    label: 'Other Visa Work (i.e. Open)',
    value: 'otherVisa',
  },
  {
    label: 'Student Visa',
    value: 'studentVisa',
  },
  {
    label: 'Other Temporary Visa',
    value: 'otherTemporaryVisa',
  },
];

export const highestEducationLevelOptions = [
  {
    label: 'Select education level',
    value: '',
  },
  {
    label: 'High School Diploma',
    value: 'highSchoolGed',
  },
  {
    label: 'Community College or Trade School Certification',
    value: 'communityTrade',
  },
  {
    label: 'Associate Degree',
    value: 'associate',
  },
  {
    label: 'Bachelors Degree',
    value: 'bachelor',
  },
  {
    label: 'Masters',
    value: 'master',
  },
  {
    label: 'PhD',
    value: 'phd',
  },
  {
    label: 'No degree',
    value: 'none',
  },
];

export const planCurrencyOptions = [
  {
    label: 'US Dollars',
    value: 'USD',
  },
  {
    label: 'Euros',
    value: 'EUR',
  },
  {
    label: 'Canadian Dollars',
    value: 'CAD',
  },
];

export const paymentPlanOptions = [
  {
    label: 'Monthly',
    value: planPaymentEnum.MONTHLY,
  },
  {
    label: 'One-Time',
    value: planPaymentEnum.ONETIME,
  },
];
