import React from 'react';
import { rentalTypeEnum } from 'utils/enums';
import StatusLabelView from 'components/shared/status-label';

const RentalType = ({ rentalType = 'Apartment', size = 'base' }) => {
  // DATA INITIALIZATION
  let containerSizeStyle = '';
  let containerTextBgStyles = '';
  let circleStyles = '';

  switch (size) {
    case 'lg':
      containerSizeStyle = 'text-lg px-4';
      break;
    case 'base':
      containerSizeStyle = 'text-base px-3';
      break;
    case 'sm':
      containerSizeStyle = 'text-sm px-2';
      break;
    case 'xs':
      containerSizeStyle = 'text-xs px-2';
      break;
    default:
      containerSizeStyle = 'text-base px-3';
  }
  switch (rentalType) {
    case rentalTypeEnum.MULTIFAMILY:
      containerTextBgStyles = 'text-[#34D399] bg-[#34D39926]';
      circleStyles = 'bg-[#34D399]';
      break;
    case rentalTypeEnum.STUDENT_HOUSING:
      containerTextBgStyles = 'text-[#9D5425] bg-[#FBBF241A]';
      circleStyles = 'bg-[#FBBF24]';
      break;
    case rentalTypeEnum.OTHER:
      containerTextBgStyles = 'text-[#9D5425] bg-[#FBBF241A]';
      circleStyles = 'bg-[#FBBF24]';
      break;
    default:
      containerTextBgStyles = 'text-gray-600 bg-gray-200';
      circleStyles = 'bg-gray-400';
  }

  return (
    <StatusLabelView
      containerTextBgStyles={containerTextBgStyles}
      circleStyles={circleStyles}
      status={rentalType}
      containerSizeStyle={containerSizeStyle}
    />
  );
};

export default RentalType;
